<template>
    <div class="sa-new-feedback__card" id="sa-new-feedback-s6">
        <el-form
            :model="creationContainer.feedbackSessionPayload"
            ref="createFeedbackFormRef"
            :rules="createFeedbackFormRules"
        >
            <div class="form-item mb-8">
                <div class="sa-step6__label">{{ $t('Title') }}</div>
                <div class="sa-step6__title-text">
                    <div class="title-input">
                        <el-form-item prop="title">
                            <el-input v-model="creationContainer.feedbackSessionPayload.title" :placeholder="$t('Enter Session Title')" />
                        </el-form-item>
                    </div>
                </div>
            </div>
            <div class="form-item mb-14">
                <div class="sa-step6__label">{{ $t('Description') }}</div>
                <div class="sa-step6__description-text">
                    <div class="description-input">
                        <el-form-item prop="description">
                            <el-input
                                v-model="creationContainer.feedbackSessionPayload.description"
                                type="textarea"
                                :rows="5"
                                :placeholder="$t('Enter Session Description')"
                            />
                        </el-form-item>
                    </div>
                </div>
            </div>
        </el-form>

        <div class="info-row mb-14">
            <div class="info-row-card">
                <div class="form-item mb-5">
                    <div class="sa-step6__label">{{ $t('Product Area') }}</div>
                    <div class="sa-step6__info-text">
                        {{ creationContainer.feedbackSessionPayload.productAreas.join(', ') }}
                    </div>
                </div>
                <div class="form-item">
                    <div class="sa-step6__label">{{ $t('Countries') }}</div>
                    <div class="sa-step6__info-text">
                        {{ creationContainer.selectedBanksCountries.join(', ') }}
                    </div>
                </div>
            </div>
            <div class="info-row-card">
                <div class="form-item mb-5">
                    <div class="sa-step6__label">{{ $t('Expiration Date') }}</div>
                    <div class="sa-step6__info-text">
                        {{ moment(creationContainer.feedbackSessionPayload.sessionEnd).format("MMM Do 'YY") }}
                        <span>{{ $t('Expires in Days', { day: creationContainer.differenceDay }) }}</span>
                    </div>
                </div>
                <div class="form-item">
                    <div class="sa-step6__label">{{ $t('Phase') }}</div>
                    <div class="sa-step6__info-text">
                        {{ creationContainer.feedbackSessionPayload.phases.join(', ') }}
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6 pb-5 pb-lg-0">
                <div class="info-list-card">
                    <div class="info-list-card__title">
                        {{ $t('Selected Banks & Countries') }}
                    </div>
                    <div class="info-list-card__wrapper">
                        <div class="info-list-card__item" v-for="bank in creationContainer.feedbackSessionPayload.selectedBanks" :key="bank.id">
                            <div class="user-info">
                                <img :src="bank.icon_path" alt="">
                                <span>{{ bank.name }}</span>
                            </div>
                            <div class="country-label">
                                {{ bank.countries.length }} {{ $t('countries selected') }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="info-list-card pb-0">
                    <div class="info-list-card__title">
                        {{ $t('Selected Customers') }}
                    </div>
                    <div class="info-list-card__wrapper">
                        <div class="info-list-card__item" v-for="customer in creationContainer.feedbackSessionPayload.selectedCustomers" :key="customer.businessId">
                            <div class="user-info">
                                <img :src="customer.iconPath" alt="">
                                <span>{{ customer.businessName }}</span>
                            </div>
                            <div class="user-label">
                                <inline-svg src="/media/buying/icons/users-icon.svg"></inline-svg>
                                <span>{{ customer.matchedSelectedUsers.length }}/{{ customer.users.length }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="info-list-card__view-details" @click="viewDetailsModal = true">
                        {{ $t('View Details') }}
                    </div>
                </div>
            </div>
        </div>

        <div class="stats-card">
            <div class="stats-card__item">
                <div class="stats-card__item--value">
                    {{ creationContainer.feedbackSessionPayload.selectedCustomers.length }}
                </div>
                <div class="stats-card__item--text">
                    {{ $t('Total Number of Customers') }}
                </div>
            </div>
            <div class="stats-card__item">
                <div class="stats-card__item--value">
                    {{ creationContainer.feedbackSessionPayload.selectedBanks.length }}
                </div>
                <div class="stats-card__item--text">
                    {{ $t('Total Number of Banks') }}
                </div>
            </div>
            <div class="stats-card__item">
                <div class="stats-card__item--value">
                    {{ creationContainer.customersData.getAllSelectedUsers.length }}
                </div>
                <div class="stats-card__item--text">
                    {{ $t('Total Number of potential respondents') }}
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-center mt-15">
            <button
                :data-kt-indicator="loading ? 'on' : 'off'"
                :disabled="loading"
                class="main-btn d-block btn continue-button"
                @click.prevent="launchSession()"
            >
                <span class="indicator-label"> {{ $t('Launch this Session') }}</span>

                <span class="indicator-progress">
                          {{ $t("pleaseWait") }}
                          <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
            </button>
        </div>
    </div>

    <SACustomersIncludedModal
        v-if="creationContainer"
        :visibility="viewDetailsModal"
        :creationContainer="creationContainer"
        @close="viewDetailsModal = false"
    />
</template>

<script>
import { Constants } from "@/core/config/constants";
import { SuperAdminFeedbackCreationContainer } from "@/store/models/super-admin/SuperAdminFeedbackCreationContainer";
import moment from "moment";
import store from "@/store";
import NotificationService from "@/buying-teams/services/NotificationService";
import {redirectToRouteWithName} from "@/buying-teams/helpers/UrlHelper";
import SACustomersIncludedModal from "@/buying-teams/pages/super-admin/feedbacks/feedbacks-creation/SACustomersIncludedModal";

export default {
    name: "SAStepSixSessionSummary",
    components: {SACustomersIncludedModal},
    props: {
        creationContainer: [Object, SuperAdminFeedbackCreationContainer]
    },
    data() {
        return {
            Constants,
            moment,
            loading: false,
            viewDetailsModal: false,
        }
    },
    computed: {
        createFeedbackFormRules() {
            return {
                title: [
                    { required: true, message: this.$t("Please input title"), trigger: "change" },
                ],
                description: [
                    { required: true, message: this.$t("Please input description"), trigger: "change" },
                ]
            }
        }
    },
    methods: {
        async launchSession() {
            this.loading = true;

            if (this.creationContainer.customQuestionsData) {
                await this.creationContainer.customQuestionsData.launchQuestions(this.creationContainer.feedbackSessionPayload.surveyId);
            }

            store.dispatch('bankSurveysSubmit', this.creationContainer.feedbackSessionLaunchPayload)
            .then(() => {
                NotificationService.swalCustom({
                    title: this.$t('Success'),
                    text: this.$t('Feedback Session successfully created'),
                    icon: "success"
                });
                redirectToRouteWithName('super-admin-dashboard')
            })
            .finally(() => {
                this.loading = false;
            })
        }
    }
}
</script>

<style lang="scss">
#sa-new-feedback-s6 {
    margin-top: 41px;
    .title-input {
        .el-input__inner {
            font-weight: 500;
            font-size: 24px;
            line-height: 170.02%;
            color: #4F4F4F;
            border: none;
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
            padding-left: 0;
            padding-top: 0;
            padding-bottom: 13px;
            border-radius: 0;
            height: 59px;
        }
    }
    .description-input {
        .el-textarea__inner {
            font-weight: 500;
            font-size: 18px;
            line-height: 158%;
            color: #4F4F4F;
            border: none;
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
            min-height: 65px;
            white-space: pre-line;
            padding-left: 0;
            padding-top: 0;
            padding-bottom: 13px;
            border-radius: 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.05);
        }
    }
    .sa-step6 {
        &__label {
            font-weight: 400;
            font-size: 18px;
            line-height: 170.02%;
            color: #A8A5A5;
            margin-bottom: 10px;
        }
        &__description-text {

        }
        &__info-text {
            font-weight: 500;
            font-size: 18px;
            line-height: 158%;
            color: #4F4F4F;
            span {
                font-weight: 300;
                font-size: 16px;
                line-height: 170.02%;
                color: #4F4F4F;
                margin-left: 5px;
            }
        }
    }
    .info-row {
        display: flex;
        .info-row-card {
            width: 50%;
            &:nth-child(1) {
                padding-right: 57px;
                border-right: 1px solid rgba(0, 0, 0, 0.05);
            }
            &:nth-child(2) {
                padding-left: 57px;
            }
        }
    }

    .info-list-card {
        background: #FFFFFF;
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.04), 0px 5px 18px rgba(0, 0, 0, 0.1);
        border-radius: 7px;
        padding: 20px 23px;
        padding-bottom: 31px;
        min-height: 100%;
        &__title {
            font-weight: 600;
            font-size: 18px;
            line-height: 158%;
            color: #4F4F4F;
            padding-bottom: 30px;
        }
        &__wrapper {
            display: flex;
            flex-direction: column;
            gap: 20px;
        }
        &__item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .user-info {
                display: flex;
                align-items: center;
                img {
                    width: 28px;
                    height: 28px;
                    border-radius: 50%;
                    margin-right: 15px;
                }
                span {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 158%;
                    color: #4F4F4F;
                }
            }
            .country-label {
                background: #F8F8F8;
                border-radius: 5px;
                padding: 2px 4px;
                font-weight: 500;
                font-size: 16px;
                line-height: 158%;
                color: #4F4F4F;
            }
            .user-label {
                background: #F8F8F8;
                border-radius: 6.58407px;
                padding: 8px;
                span {
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 158.02%;
                    color: #2B2B2B;
                    margin-left: 8px;
                }
            }
        }
        &__view-details {
            font-weight: 600;
            font-size: 18px;
            line-height: 158%;
            text-align: center;
            color: #435BF4;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            padding-top: 12px;
            padding-bottom: 12px;
            cursor: pointer;
            margin-top: 12px;
        }
    }

    .stats-card {
        background: #FFFFFF;
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.04), 0px 5px 18px rgba(0, 0, 0, 0.1);
        border-radius: 7px;
        padding: 20px;
        margin-top: 16px;
        display: flex;
        &__item {
            padding: 9px 15px 18px 15px;
            width: 100%;
            &:nth-child(2) {
                border-left: 1px solid rgba(0, 0, 0, 0.1);
                border-right: 1px solid rgba(0, 0, 0, 0.1);
            }
            &--value {
                font-weight: 600;
                font-size: 24px;
                line-height: 170.02%;
                text-align: center;
                color: #435BF4;
                margin-bottom: 14px;
            }
            &--text {
                font-weight: 500;
                font-size: 16px;
                line-height: 158%;
                text-align: center;
                color: #4F4F4F;
            }
        }
    }

    @media (max-width: 992px) {
        .title-input .el-input__inner {
            font-size: 20px;
        }
        .description-input .el-textarea__inner {
            font-size: 16px;
        }
        .sa-step6__label, .sa-step6__info-text {
            font-size: 16px;
        }
        .info-row {
            flex-direction: column;
            .info-row-card {
                width: 100%;
                padding: 0 !important;
                border: none !important;
                margin-bottom: 1.25rem;
            }
        }
        .stats-card {
            flex-direction: column;
            &__item:nth-child(2) {
                border-left: 0;
                border-right: 0;
                border-top: 1px solid rgba(0, 0, 0, 0.1);
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            }
        }
    }

    @media (max-width: 600px) {
        .info-list-card__item {
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
            &:not(:last-child) {
                border-bottom: 1px solid #f1f1f1;
                padding-bottom: 10px;
            }
            .country-label {
                font-size: 14px;
            }
            .user-info {
                img {
                    margin-right: 10px;
                    width: 20px;
                    height: 20px;
                }
                span {
                    font-size: 14px;
                }
            }
        }
    }
}
</style>
