<template>
    <el-select
        v-model="selectValue"
        multiple
        :class="{'is-error': error}"
        popper-class="group-select"
        @change="handleChange"
    >
        <template #prefix>{{ selectValue.length }}/{{ optionsData.length }}</template>
        <el-option-group
            v-for="group in options"
            :key="group.label"
            :label="group.label"
            @change="$emit('change')"
        >
            <el-option
                v-if="group.options.length"
                v-for="item in group.options"
                :key="item.id"
                :label="item.name"
                :value="item.id"
                :disabled="item.disabled"
            />
            <div class="no-data-found" v-else>{{ $t('No Data') }}</div>
        </el-option-group>
    </el-select>
</template>

<script>
export default {
    name: "CustomSelfGroupSelect",
    props: {
        modelValue: Array,
        optionsData: Array,
        error: Boolean,
    },
    data() {
        return {
            selectValue: []
        }
    },
    created() {
        this.selectValue = this.modelValue.map(v => v.id);
    },
    methods: {
        handleChange() {
            const dataForEmit = this.optionsData.filter(v => this.selectValue.includes(v.id));
            this.$emit('update:modelValue', dataForEmit)
        }
    },
    computed: {
        options() {
            let data = this.optionsData;
            return [
                {
                    label: this.$t('No Matches'),
                    options: this.selectValue.length ? data.filter(i => !this.selectValue.includes(i.id)) : data,
                },
                {
                    label: this.$t('Matched & Selected'),
                    options: this.selectValue.length ? data.filter((i) => this.selectValue.includes(i.id)) : [{
                        name: this.$t('No Data'),
                        id: '',
                        disabled: true
                    }],
                }
            ]
        }
    }
}
</script>

<style lang="scss">
.group-select {
    .el-select-group__wrap {
        min-width: 298px;
        overflow-x: hidden;

        &:first-child {
            .el-select-group .el-select-dropdown__item {
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                color: #515151;
                height: 30px;
                display: flex;
                align-items: center;
            }
        }

        &:last-child {
            .el-select-group .el-select-dropdown__item {
                background-color: #EEF6FF;
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                color: #435BF4;
                height: 30px;
                display: flex;
                align-items: center;

                &.is-disabled {
                    background-color: transparent;
                    color: #515151;
                    font-weight: 500;
                    opacity: 0.5;
                    font-size: 14px;
                    cursor: default;
                }
            }
        }
    }

    .el-select-dropdown__wrap {
        min-width: 298px;
        overflow-x: hidden;
    }

    .el-select-group__wrap:not(:last-of-type)::after {
        display: none;
    }

    .el-select-group__wrap:not(:last-of-type) {
        padding-bottom: 4px;
    }

    .el-select-group__title {
        font-weight: 400;
        font-size: 12px;
        line-height: 158.02%;
        color: rgba(0, 0, 0, 0.4);
        width: max-content;
        position: relative;

        &:after {
            content: "";
            position: absolute;
            width: 210px;
            height: 1px;
            background: rgba(0, 0, 0, 0.1);;
            left: 106%;
            top: 50%;
        }
    }

    .no-data-found {
        padding: 0 32px 0 20px;
        height: 30px;
        display: flex;
        align-items: center;
        background-color: transparent;
        color: #515151;
        font-weight: 500;
        opacity: 0.5;
        font-size: 14px;
    }
}
</style>
