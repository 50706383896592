<template>
    <CustomLoader v-if="pageLoading" />
    <template v-else>
        <div class="sa-new-feedback" v-if="!creationContainer.isCustomQuestionsOpen">
            <div class="sa-new-feedback__header">
                <button class="header-back-btn" @click="creationContainer.prevStep()">
                    <inline-svg src="/media/buying/icons/arrow-right.svg" />
                    <span>{{ creationContainer.currentStep === SAFeedbackStepsEnum.SELECT_BANKS ? $t('Cancel') : $t('Back') }}</span>
                </button>
                <div class="sa-new-feedback__header--title">{{ stepTitle }}</div>
                <div class="sa-new-feedback__header--step">
                <span class="text-gray">
                    {{ $t("Step") + " " + creationContainer.currentStep + " " + $t("of") + " " + creationContainer.stepsCount }}
                </span>
                    <div class="demo-progress step-progress w-100">
                        <el-progress class="w-100" :stroke-width="4" :percentage="creationContainer.stepsPercentage" />
                    </div>
                    <div class="step-progress"></div>
                </div>
            </div>

            <!-- Step 1 -->
            <SAStepOneSelectBanks
                v-if="creationContainer.currentStep === SAFeedbackStepsEnum.SELECT_BANKS"
                :creationContainer="creationContainer"
            />

            <!-- Step 2 -->
            <SAStepTwoBasicInfo
                v-if="creationContainer.currentStep === SAFeedbackStepsEnum.SET_BASIC_INFO"
                :creationContainer="creationContainer"
            />

            <!-- Step 3 -->
            <SAStepThreeDescriptionDeadline
                v-if="creationContainer.currentStep === SAFeedbackStepsEnum.SESSION_DESCRIPTION"
                :creationContainer="creationContainer"
            />

            <!-- Step 4 -->
            <SAStepFourQuestionnaire
                v-if="creationContainer.currentStep === SAFeedbackStepsEnum.SET_QUESTIONNAIRE"
                :creationContainer="creationContainer"
            />

            <!-- Step 5 -->
            <SAStepFiveSelectCustomers
                v-if="creationContainer.currentStep === SAFeedbackStepsEnum.SELECT_CUSTOMER"
                :creationContainer="creationContainer"
            />

            <!-- Step 6 -->
            <SAStepSixSessionSummary
                v-if="creationContainer.currentStep === SAFeedbackStepsEnum.SESSION_SUMMARY"
                :creationContainer="creationContainer"
            />
        </div>
        <div class="sa-new-feedback" v-else>
            <CustomQuestionsContainer
                :questionsContainer="creationContainer.customQuestionsData"
                :back-to-number="4"
                @handleBack="handleBack"
                @continueWithSelectedQuestions="onContinueWithSelectedQuestions"
            />
        </div>
    </template>
</template>

<script>
import { defineComponent } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import {
    SAFeedbackStepsEnum,
    SuperAdminFeedbackCreationContainer
} from "@/store/models/super-admin/SuperAdminFeedbackCreationContainer";
import SAStepOneSelectBanks from "./SAStepOneSelectBanks.vue";
import SAStepTwoBasicInfo from "./SAStepTwoBasicInfo.vue";
import SAStepThreeDescriptionDeadline from "./SAStepThreeDescriptionDeadline.vue";
import SAStepFourQuestionnaire from "./SAStepFourQuestionnaire.vue";
import SAStepFiveSelectCustomers from "./SAStepFiveSelectCustomers.vue";
import SAStepSixSessionSummary from "./SAStepSixSessionSummary.vue";
import store from "@/store";
import {StaticDataEnum} from "@/store/enums/StaticDataEnum";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";
import CustomQuestionsContainer from "@/buying-teams/shared-components/custom-questions/CustomQuestionsContainer";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
    name: "SuperAdminNewFeedbackSession",
    components: {
        CustomQuestionsContainer,
        CustomLoader,
        SAStepSixSessionSummary,
        SAStepFiveSelectCustomers,
        SAStepFourQuestionnaire,
        SAStepThreeDescriptionDeadline,
        SAStepTwoBasicInfo,
        SAStepOneSelectBanks
    },
    data() {
        return {
            SAFeedbackStepsEnum,
            creationContainer: null,
            pageLoading: true,
        }
    },
    computed: {
        stepTitle() {
            switch (this.creationContainer.currentStep) {
                case SAFeedbackStepsEnum.SELECT_BANKS: return this.$t('Select Banks')
                case SAFeedbackStepsEnum.SET_BASIC_INFO: return this.$t('Set Basic Info')
                case SAFeedbackStepsEnum.SESSION_DESCRIPTION: return this.$t('Create Session description & deadline')
                case SAFeedbackStepsEnum.SET_QUESTIONNAIRE: return this.$t('Set Questionnaire')
                case SAFeedbackStepsEnum.SELECT_CUSTOMER: return this.$t('Select Customers')
                case SAFeedbackStepsEnum.SESSION_SUMMARY: return this.$t('Session Summary')
            }
        },
        allBanks() {
            return store.getters.allBanks;
        },
        allCountries() {
            return store.getters.allCountries;
        },
        allProductAreas() {
            return store.getters.allProductAreas;
        },
    },
    async created() {
        setCurrentPageBreadcrumbs({
            title: this.$t('New Feedback session'),
            subTitle: this.$t('Select which banks and countries to review in the feedback session')
        });
        await this.getStaticData();
        this.creationContainer = new SuperAdminFeedbackCreationContainer(this.allProductAreas, this.allBanks, this.allCountries);
        this.pageLoading = false;
    },
    methods: {
        getStaticData() {
            return Promise.all([
                store.dispatch('fetchStaticData', StaticDataEnum.PRODUCT_AREAS),
                store.dispatch('fetchStaticData', StaticDataEnum.BANKS),
                store.dispatch('fetchStaticData', StaticDataEnum.COUNTRIES),
            ])
        },
        handleBack() {
            Swal.fire({
                title: this.$t('Cancel and back to step NUM', { n: 4 }),
                text: this.$t('Are you sure you want to go back to step NUM, the selected questions will be discarded', { n: 4 }),
                showCancelButton: true,
                confirmButtonText: this.$t('Discard changes'),
                customClass: 'cancel-and-back',
                cancelButtonText: this.$t('Cancel')
            }).then((result) => {
                if (result.value) {
                    this.creationContainer.isCustomQuestionsOpen = false;
                }
            })
        },
        onContinueWithSelectedQuestions() {
            this.creationContainer.setSelectedCustomQuestions();
            this.creationContainer.isCustomQuestionsOpen = false;
        }
    }
})
</script>

<style lang="scss">
.sa-new-feedback {
    margin-top: 20px;
    background: #FFFFFF;
    box-shadow: 0 9px 29px rgba(0, 0, 0, 0.06);
    border-radius: 12px;
    padding: 20px 53px 48px 38px;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &--title {
            font-weight: 500;
            font-size: 18px;
            line-height: 170.02%;
            color: #4F4F4F;
        }
        &--step {
            .el-progress__text {
                display: none;
            }
            .text-gray {
                font-weight: 400;
                font-size: 18px;
                line-height: 178.52%;
                color: #434343;
                opacity: 0.6;
            }
        }
    }
    &__card {

    }
    .header-back-btn {
        font-weight: 500;
        border: none;
        font-size: 18px;
        line-height: 170.02%;
        color: #4F4F4F;
        background: transparent;
        display: flex;
        align-items: center;
        gap: 10px;
        svg {
            transform: rotate(-180deg);
        }
        path {
            stroke: #4F4F4F;
        }
    }
    .continue-button {
        width: 334px;
        height: 48px;
        @media (max-width: 400px) {
            width: 100%;
        }
    }

    @media (max-width: 600px) {
        padding: 20px 24px;
        position: relative;

        &__header {
            &--title {
                position: absolute;
                top: -40px;
                left: 0;
                right: 0;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}
</style>
