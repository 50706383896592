<template>
    <div class="sa-new-feedback__card" id="sa-new-feedback-s4">
        <div class="sa-step4__title">{{ $t('Set up the Questions') }}</div>
        <div class="sa-step4__text">
            {{ $t('Choose the questions from the selected questionnaires.') }}
        </div>

        <div class="questions-collapse">
            <el-collapse accordion>
                <el-collapse-item
                    v-for="(item, i) in creationContainer.questionsDataList"
                    :key="i"
                    :name="'question' + i"
                >
                    <template #title>
                        <span>{{ item.title }}</span>
                        <h3 class="questions-collapse__checkedCount">{{ creationContainer.activeQuestionsCount(item.phases) }}</h3>
                    </template>
                    <div class="questions-collapse__content">
                        <div
                            class="questions-collapse__switch"
                            v-for="(phase, j) in item.phases"
                            :key="j"
                        >
                            <h3 class="questions-collapse__title">{{ phase.title }}</h3>
                            <div
                                class="questions-collapse__switch--item"
                                v-for="(question, ind) in phase.questionList"
                                :key="ind"
                            >
                                <div class="questions-collapse__question">{{ question.question }}</div>
                                <el-switch
                                    v-model="question.is_enabled"
                                    @change="questionsToggle(question)"
                                />
                            </div>
                        </div>
                    </div>
                    <button class="questions-collapse__add-question" v-if="false">
                        + {{ $t('Add Question') }}
                    </button>
                </el-collapse-item>
            </el-collapse>
        </div>

        <div class="custom-questions-wrapper">
            <div class="custom-questions-wrapper-action">
                <div class="custom-questions-wrapper__title">
                    <h2>{{ $t("Custom Questions") }}</h2>
                    <p>{{ $t("You can add your own personalized questions in the Session.") }}</p>
                </div>
                <button
                    :data-kt-indicator="addCustomQuestionLoading ? 'on' : 'off'"
                    :disabled="addCustomQuestionLoading"
                    class="custom-questions-wrapper__button"
                    @click.prevent="handleAddQuestion"
                >
                    <span class="indicator-label">+ {{ $t("Create Custom Questions") }}</span>

                    <span class="indicator-progress">
                        {{ $t("pleaseWait") }}
                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                </button>
            </div>

            <div class="custom-questions-added" v-if="creationContainer.feedbackSessionPayload.selectedCustomQuestions.length">
                <div class="custom-questions-added__title">
                    <div class="custom-questions-added__title--icon">
                        <inline-svg src="/media/buying/icons/check-icon.svg" width="10" height="8" />
                    </div>
                    <span>{{ $t('Custom Questions added successfully!') }}</span>
                </div>
                <div class="custom-questions-added__count">
                    <span>{{ creationContainer.feedbackSessionPayload.selectedCustomQuestions.length }}</span>
                    <button @click="creationContainer.isCustomQuestionsOpen = true">
                        <img src="/media/buying/icons/arrow-right.svg" alt="">
                    </button>
                </div>
            </div>
        </div>

        <div class="d-flex justify-content-center mt-12">
            <button
                :data-kt-indicator="loading ? 'on' : 'off'"
                :disabled="loading"
                class="main-btn d-block btn continue-button"
                @click.prevent="handleNextStep()"
            >
                <span class="indicator-label"> {{ $t('Continue') }}</span>

                <span class="indicator-progress">
                      {{ $t("pleaseWait") }}
                      <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
            </button>
        </div>
    </div>
</template>

<script>
import { SuperAdminFeedbackCreationContainer } from "@/store/models/super-admin/SuperAdminFeedbackCreationContainer";
import store from "@/store";

export default {
    name: "SAStepFourQuestionnaire",
    props: {
        creationContainer: [Object, SuperAdminFeedbackCreationContainer]
    },
    data() {
        return {
            loading: false,
            addCustomQuestionLoading: false,
        }
    },
    methods: {
        handleNextStep() {
            this.loading = true;
            store.dispatch('getMatchedBusinesses', {
                bank_ids: this.creationContainer.feedbackSessionPayload.selectedBanks.map(b => b.id),
                survey_id: this.creationContainer.feedbackSessionPayload.surveyId
            }).then((res) => {
                this.creationContainer.setCustomersData(res);
                this.creationContainer.nextStep();
            }).finally(() => {
                this.loading = false;
            })
        },
        questionsToggle: async function(question) {
            let params = {
                survey_id: this.creationContainer.feedbackSessionPayload.surveyId,
                question_id: question.id,
                is_enabled: question.is_enabled ? 1 : 0
            };
            await store.dispatch("questionsToggle", params)
        },
        handleAddQuestion() {
            this.addCustomQuestionLoading = true;
            store.dispatch('getCustomQuestions', {
                filters: {
                    product_areas: this.creationContainer.feedbackSessionPayload.productAreas
                }
            }).then((res) => {
                this.creationContainer.setCustomQuestionsData(res);
            }).finally(() => {
                this.addCustomQuestionLoading = false;
            })
        }
    },
    created() {
        this.creationContainer.checkResetCustomQuestionsContainer();
    }
}
</script>

<style lang="scss">
#sa-new-feedback-s4 {
    margin-top: 41px;

    .sa-step4 {
        &__title {
            font-weight: 700;
            font-size: 22px;
            line-height: 27px;
            color: #000000;
            margin-bottom: 14px;
        }
        &__text {
            font-weight: 400;
            font-size: 16px;
            line-height: 178%;
            color: #595959;
            margin-bottom: 30px;
        }
    }

    .questions-collapse {
        .el-collapse {
            border: none;
            display: flex;
            flex-direction: column;
            gap: 12px;
            .el-collapse-item__header, .el-collapse-item__wrap {
                border-bottom: none;
                background: transparent;
            }

            .el-collapse-item {
                background: #F8F8F8;
                border-radius: 7px;
                .el-collapse-item__header {
                    position: relative;
                    padding: 30px 20px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    height: 82px;
                    padding-right: 86px;
                    border-bottom: 1px solid transparent;
                    span {
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 22px;
                        color: #2B2B2B;
                    }
                    .questions-collapse__checkedCount {
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 22px;
                        color: #2B2B2B;
                        margin-bottom: 0;
                    }
                    .el-collapse-item__arrow {
                        position: absolute;
                        right: 40px;
                        width: 20px;
                        height: 20px;
                        transform: rotate(90deg);
                        svg {
                            width: 20px;
                            height: 20px;
                        }
                        &.is-active {
                            transform: rotate(-90deg);
                        }
                    }
                }
                .el-collapse-item__content {
                    padding-bottom: 0;
                    .questions-collapse__add-question {
                        background: #FCFCFC;
                        border: none;
                        border-top: 1px solid #E0E0E0;
                        border-radius: 0px 0px 14px 14px;
                        padding: 26px;
                        width: 100%;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 19px;
                        text-align: center;
                        color: #435BF4;
                        transition: .3s;
                        &:hover {
                            background: #f1f6ff;
                        }
                    }
                }
                .questions-collapse {
                    &__content {
                        padding: 25px 47px 35px 20px;
                    }
                    &__title {
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 19px;
                        color: #000000;
                        margin-bottom: 28px;
                    }
                    &__switch {
                        &:not(:last-child) {
                            margin-bottom: 30px;
                        }
                        &--item {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            gap: 10px;
                            &:not(:last-child) {
                                margin-bottom: 10px;
                                padding-bottom: 12px;
                            }
                            .questions-collapse__question {
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 19px;
                                color: #272727;
                            }
                        }
                    }
                }
                &.is-active {
                    background: #FFFFFF;
                    border: 1px solid #E0E0E0;
                    box-shadow: 0px 9px 22px rgba(0, 0, 0, 0.11);
                    border-radius: 14px;
                    .el-collapse-item__header {
                        background: rgba(67, 91, 244, 0.02);
                        border-bottom: 1px solid #E0E0E0;
                    }
                }
            }
        }
    }

    .custom-questions-wrapper {
        margin-top: 32px;
        padding-top: 30px;
        padding-bottom: 30px;
        margin-bottom: 30px;
        margin-left: -38px;
        margin-right: -53px;
        padding-left: 38px;
        padding-right: 53px;
        border-top: 1px solid #0000001A;
        border-bottom: 1px solid #0000001A;
        .custom-questions-wrapper-action {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        &__title {
            h2 {
                font-weight: 700;
                font-size: 22px;
                line-height: 27px;
                color: #000000;
                margin-bottom: 12px;
            }
            p {
                font-weight: 400;
                font-size: 16px;
                line-height: 178%;
                color: #595959;
                margin-bottom: 0;
            }
        }
        &__button {
            background: #F6F6F6;
            border-radius: 30px;
            padding: 20px;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: #435BF4;
            border: none;
            min-width: 280px;
        }
    }

    .custom-questions-added {
        background: rgba(67, 91, 244, 0.05);
        border-radius: 7px;
        padding: 26px 40px 26px 10px;
        margin-top: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__title {
            display: flex;
            &--icon {
                margin-right: 13px;
                background: #435BF4;
                width: 22px;
                height: 22px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                svg path {
                    fill: #fff;
                }
            }
            span {
                font-weight: 700;
                font-size: 18px;
                line-height: 22px;
                color: #435BF4;
            }
        }

        &__count {
            display: flex;
            align-items: center;
            span {
                font-weight: 700;
                font-size: 18px;
                line-height: 22px;
                color: #2B2B2B;
                margin-right: 33px;
            }
            button {
                width: 30px;
                height: 30px;
                background: rgba(67, 91, 244, 0.05);
                border-radius: 50%;
                border: none;
                transition: .3s ease-in-out;
                &:hover {
                    background: rgb(185 195 255);
                }
            }
        }
    }

    @media (max-width: 992px) {
        .custom-questions-wrapper {
            .custom-questions-wrapper-action {
                flex-direction: column;
                align-items: flex-start;
            }
            &__title {
                margin-bottom: 20px;
            }
        }
    }

    @media (max-width: 600px) {
        .custom-questions-wrapper {
            margin-left: -24px;
            margin-right: -24px;
            padding-left: 24px;
            padding-right: 24px;
        }
        .questions-collapse .el-collapse .el-collapse-item {
            .el-collapse-item__header {
                padding-right: 54px;
                height: 64px;

                .el-collapse-item__arrow {
                    right: 16px;
                }
            }
            .questions-collapse__content {
                padding-right: 20px;
            }
        }
    }
}
</style>
