import {Constants} from "@/core/config/constants";
import { BankModelMin } from "@/store/models/bank/BankModelMin";
import { v4 as uuidv4 } from "uuid";

export class SuperAdminSelectCustomersContainer {
    surveyId: number;
    countries: string[];
    banks: SACCUserBankModel[];
    productAreas: string[];
    allCustomers: SACCBusinessModel[] = [];
    finalData: Map<number, SACCBusinessModel>;

    constructor(obj: any, banks: BankModelMin[]) {
        this.surveyId = obj ? obj.survey.id : null;
        this.countries = obj ? obj.survey.countries : [];
        this.productAreas = obj ? obj.survey.product_areas : [];
        this.banks = obj ? banks.map(b => new SACCUserBankModel(b)) : [];
        this.allCustomers = (obj.allBusinesses || []).map(c => new SACCBusinessModel(c, this.countries, this.productAreas, this.banks));
        this.finalData = new Map<number, SACCBusinessModel>();
    }

    get selectedCustomers() {
        return this.allCustomers.filter((c: SACCBusinessModel) => c.isSelected);
    }

    get getFinalData(): SACCBusinessModel[] {
        return Array.from(this.finalData.values());
    }

    get getAllSelectedUsers() {
        let users = new Map();
        this.allCustomers.forEach((b: SACCBusinessModel) => {
            if (b.isSelected) {
                this.finalData.set(b.businessId, b);
                b.matchedSelectedUsers.forEach((u: SACCUserModel) => {
                    if (u.selectedCountries.length && u.selectedProductAreas.length && u.selectedBanks.length) {
                        users.set(u.userId, u);
                    }
                })
            }
        })
        return Array.from(users.values()).map((u: SACCUserModel) => {
            return {
                id: u.userId,
                product_areas: u.selectedProductAreas,
                countries: u.selectedCountries,
                bank_ids: u.selectedBanks.map(b => b.id)
            }
        });
    }

    getFilteredData(search: string): SACCBusinessModel[] {
        let data: SACCBusinessModel[] = this.allCustomers;
        return search ? data.filter((item: SACCBusinessModel) => {
            return item.businessName.toLowerCase().includes(search.toLowerCase())
        }) : data;
    }

    selectAll() {
        this.allCustomers.forEach((val: SACCBusinessModel) => {
            val.setAllUsersSelected(this.countries, this.productAreas, this.banks);
        })
    }

    deselectAll() {
        this.allCustomers.forEach((val: SACCBusinessModel) => {
            val.removeAllUsersSelected();
        });
    }

    addNewCustomers(data) {
        let payload = new SACCBusinessModel({
            id: data.businessId,
            name: data.companyName,
            users: data.users.map((val: any) => {return {
                ...val,
                country: '-',
                first_name: val.email,
                job_title: '-',
            }}),
        }, this.countries, this.productAreas, this.banks, true);
        this.allCustomers.unshift(payload);
    }

    checkCustomerExist(businessId) {
        return this.allCustomers.find((item: SACCBusinessModel) => item.businessId === businessId);
    }

    createNewBusinessModelData(data: any) {
        return new SACCBusinessModel(data, this.countries, this.productAreas, this.banks)
    }
}

export class SACCBusinessModel {
    businessId: number;
    businessName: string;
    iconPath: string;
    users: SACCUserModel[];
    countries: Set<any>;
    productAreas: Set<any>;
    banks: Map<number, SACCUserBankModel>;
    matchedSelectedUsers: any[];
    isSelected: boolean;
    isRegistered: boolean;

    constructor(obj, baseCountries, baseProductAreas, baseBanks, isNewUser: boolean = false) {
        this.matchedSelectedUsers = [];
        this.users = [];
        this.isSelected = false;
        this.countries = new Set();
        this.productAreas = new Set();
        this.banks = new Map<number, SACCUserBankModel>();
        this.businessId = obj['id'];
        this.businessName = obj['name'];
        this.isRegistered = obj['is_registered'];
        this.iconPath = obj['icon_path'] ? obj['icon_path'] : Constants.BUSINESS_TMP_LOGO;
        this.setUserData(obj['users'], baseCountries, baseProductAreas, baseBanks, isNewUser);
    }

    setUserData(users, baseCountries, baseProductAreas, baseBanks, isNewUser) {
        this.users = users ? users.map(u => {

            const matchedCountries: string[] = [];
            const matchedProductAreas: string[] = [];
            let matchedBanks: SACCUserBankModel[] = [];

            baseCountries.forEach(ct => {
                if (u.countries.includes(ct)) {
                    matchedCountries.push(ct);
                    this.countries.add(ct);
                }
            })

            baseProductAreas.forEach(pa => {
                if (u.product_areas.includes(pa)) {
                    matchedProductAreas.push(pa);
                    this.productAreas.add(pa);
                }
            })

            if (isNewUser) {
                matchedBanks = baseBanks;
                matchedBanks.forEach((b: SACCUserBankModel) => {
                    this.banks.set(b.id, b);
                })
            } else {
                baseBanks.forEach((b: SACCUserBankModel) => {
                    let bankIds: number[] = u.banks.map(ub => ub.id);
                    if (bankIds.includes(b.id)) {
                        let bank = new SACCUserBankModel(b);
                        matchedBanks.push(bank);
                        this.banks.set(bank.id, bank);
                    }
                })
            }

            if (matchedCountries.length && matchedProductAreas.length && matchedBanks.length) {
                this.matchedSelectedUsers.push(new SACCUserModel(u, matchedCountries, matchedProductAreas, matchedBanks));
                this.isSelected = true;
            }

            return new SACCUserModel(u, matchedCountries, matchedProductAreas, matchedBanks);
        }) : [];
    }

    setAllUsersSelected(baseCountries, baseProductAreas, baseBanks) {
        this.countries = baseCountries;
        this.productAreas = baseProductAreas;
        this.banks = baseBanks;
        this.users.forEach((user) => {
            user.setUserCountryProductAreaData(baseCountries, baseProductAreas, baseBanks);
        });
        this.matchedSelectedUsers = this.users;
        this.isSelected = true;
    }

    removeAllUsersSelected() {
        this.countries = new Set();
        this.productAreas = new Set();
        this.banks = new Map<number, SACCUserBankModel>();
        this.users.forEach((user) => {
            user.setUserCountryProductAreaData([], [], []);
        });
        this.matchedSelectedUsers = [];
        this.isSelected = false;
    }

    addUsers(ides) {
        this.matchedSelectedUsers = [];
        this.countries = new Set();
        this.productAreas = new Set();
        this.banks = new Map<number, SACCUserBankModel>();
        this.users.forEach(u => {
            if (ides.includes(u.userId)) {
                this.matchedSelectedUsers.push(u);
            }
            u.selectedCountries.forEach(c => this.countries.add(c));
            u.selectedProductAreas.forEach(p => this.productAreas.add(p));
            u.selectedBanks.forEach((b: SACCUserBankModel) => this.banks.set(b.id, b));
        })
        this.isSelected = !!this.matchedSelectedUsers.length;
    }

    cancelChanges(users: any[]) {
        this.users.forEach((u: SACCUserModel) => {
            users.forEach((item: any) => {
                if (u.userId === item.userId) {
                    u.selectedProductAreas = item.selectedProductAreas
                    u.selectedCountries = item.selectedCountries
                }
            })
        })
    }

    addNewUser(user: any, selectedBanks) {
        let newUser = new SACCUserModel({
            ...user,
            first_name: user.email,
            job_title: '-',
            country: '-',
        }, user.countries, user.product_areas, selectedBanks);

        this.matchedSelectedUsers.push(newUser);
        this.users.push(newUser);
    }

    get checkUserMatched() {
        let users: SACCUserModel[] = [];
        this.users.forEach((u: SACCUserModel) => {
            if (u.selectedCountries.length && u.selectedProductAreas.length) {
                users.push(u)
            }
        })

        if (!this.isSelected) {
            this.matchedSelectedUsers = users
        }

        return users
    }

    get getBanks() {
        return Array.from(this.banks.values());
    }

    get getCountries() {
        return Array.from(this.countries);
    }

    get getProductAreas() {
        return Array.from(this.productAreas);
    }
}

export class SACCUserModel {
    userId: number;
    countries: string[];
    productAreas: string[];
    firstName: string;
    lastName: string;
    jobTitle: string;
    country: string;
    iconPath: string;
    selectedCountries: string[];
    selectedProductAreas: string[];
    selectedBanks: SACCUserBankModel[];

    constructor(obj, matchedCountries, matchedProductAreas, matchedBanks) {
        this.userId = obj['id'];
        this.countries = obj['countries'];
        this.productAreas = obj['product_areas'];
        this.firstName = obj['first_name'] || 'Unknown';
        this.lastName = obj['last_name'] || 'Unknown';
        this.jobTitle = obj['job_title'] || 'Unknown';
        this.country = obj['country'] || 'Unknown';
        this.iconPath = obj['icon_path'] ? obj['icon_path'] : Constants.USER_TMP_LOGO;
        this.selectedCountries = matchedCountries;
        this.selectedProductAreas = matchedProductAreas;
        this.selectedBanks = matchedBanks;
    }

    setUserCountryProductAreaData(countries, productAreas, baseBanks) {
        this.selectedCountries = countries;
        this.selectedProductAreas = productAreas;
        this.selectedBanks = baseBanks;

    }
}

class SACCUserBankModel {
    id: number;
    name: string;
    iconPath: string;

    constructor(obj) {
        this.id = obj.id;
        this.name = obj.name;
        this.iconPath = obj.icon_path ? obj.icon_path : Constants.BANK_TMP_LOGO;
    }
}
