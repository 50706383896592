<template>
    <div class="sa-new-feedback__card" id="sa-new-feedback-s1">
        <div class="row">
            <div class="col-md-5">
                <div class="sa-company-banks__title">
                    {{ $t('Company Banks') }}
                    <span>{{ creationContainer.banksData.allBanks.length }}</span>
                </div>
                <div class="sa-company-banks__card">
                    <div class="sa-company-banks__card--search">
                        <img src="/media/buying/icons/search-black.svg" alt="">
                        <el-input :placeholder="$t('Search banks')" v-model="search"></el-input>
                    </div>
                    <div class="sa-company-banks__card--banks">
                        <div
                            class="sa-banks-item"
                            v-for="bank in banksDataDisplayData"
                            :key="bank"
                            :class="{'is-selected': creationContainer.banksData.isBankSelected(bank)}"
                            @click="handleSelectCountries(bank)"
                        >
                            <div class="sa-banks-item__name">
                                <img :src="creationContainer.banksData.isBankSelected(bank) ? '/media/buying/icons/check-icon-circle.svg' : bank.icon_path" alt="">
                                <span>{{ bank.name }}</span>
                            </div>
                            <img src="/media/buying/icons/arrow-right-dark.svg" alt="">
                        </div>
                        <div v-if="false" class="no-banks-found">
                            {{ $t('No banks found') }}
                        </div>
                    </div>
                </div>
                <CustomPagination
                    class="mt-3"
                    :current-page="page"
                    :total="creationContainer.banksData.getFilteredData(search).length"
                    :page-size="pageSize"
                    :hide-on-single-page="false"
                    @currentPageChange="page = $event"
                    @pageSizeChange="pageSize = $event"
                />
            </div>
            <div class="col-md-7">
                <div class="sa-selected-banks">
                    <div class="sa-selected-banks__header">
                        <div class="sa-selected-banks__header--title">
                            {{ $t('Selected Banks') }}
                            <span>{{ creationContainer.banksData.getSelectedBanks.length }}</span>
                        </div>
                        <a href="" @click.prevent="creationContainer.banksData.deleteAllSelectedBanks()">{{ $t('Clear All') }}</a>
                    </div>

                    <div class="sa-selected-banks__cards" v-if="creationContainer.banksData.getSelectedBanks.length">
                        <div class="selected-item" v-for="bank in creationContainer.banksData.getSelectedBanks" :key="bank">
                            <div class="selected-item__header">
                                <div class="selected-item__header--name">
                                    <img :src="bank.icon_path" alt="">
                                    <span>{{ bank.name }}</span>
                                </div>
                                <div class="selected-item__header--actions">
                                    <button @click="handleEditCountries(bank)">
                                        <img src="/media/buying/icons/edit_black.svg" alt="">
                                    </button>
                                    <button @click="handleDeleteCountries(bank)">
                                        <img src="/media/buying/icons/delete-icon.svg" alt="">
                                    </button>
                                </div>
                            </div>
                            <p>{{ $t('Countries of the Bank') }}</p>
                            <div class="selected-item__countries">
                                <span v-for="(country, index) in bank.selectedCountries" :key="index">
                                    {{ country }}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="no-selected" v-else>
                        <img src="/media/buying/icons/no-sa-selected-banks.svg" alt="">
                        <h4>{{ $t('Select banks') }}</h4>
                        <p>{{ $t('You need to select atleast one bank to continue') }}</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="w-100 d-flex justify-content-end mt-8">
            <button
                class="main-btn d-block btn continue-button"
                :disabled="!creationContainer.banksData.getSelectedBanks.length"
                @click="handleNextStep"
            >
                {{ $t('Continue') }}
            </button>
        </div>

        <div v-if="dialogCountriesVisible" class="sa-bank-country-modal">
            <SelectModal
                :multiple="true"
                :popupVisible="dialogCountriesVisible"
                :dataForSelect="selectCountriesValue.countries"
                :selfValue="true"
                :title="$t('Select Countries')"
                :placeholder="$t('Search Countries')"
                :dataForUpdate="selectedCountries"
                @handle-close="dialogCountriesVisible = false"
                @handle-confirm="handleConfirmCountries"
            >
                <template #subtitle>
                    <div class="sa-bank-country-modal__info">
                        <img :src="selectCountriesValue.icon_path" alt="">
                        <span>{{ selectCountriesValue.name }}</span>
                    </div>
                </template>
            </SelectModal>
        </div>
    </div>
</template>

<script>
import SelectModal from "@/buying-teams/shared-components/modals/SelectModal";
import { SuperAdminFeedbackCreationContainer } from "@/store/models/super-admin/SuperAdminFeedbackCreationContainer";
import CustomPagination from "@/buying-teams/shared-components/utils/CustomPagination.vue";

export default {
    name: "SAStepOneSelectBanks",
    components: { CustomPagination, SelectModal},
    props: {
        creationContainer: [Object, SuperAdminFeedbackCreationContainer]
    },
    data() {
        return {
            search: '',
            dialogCountriesVisible: false,
            selectedCountries: [],
            selectCountriesValue: null,
            page: 1,
            pageSize: 10,
        }
    },
    computed: {
        banksDataDisplayData() {
            return this.creationContainer.banksData.getFilteredData(this.search).slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page);
        },
    },
    methods: {
        handleEditCountries(bank) {
            this.selectedCountries = bank.selectedCountries;
            this.selectCountriesValue = bank;
            this.dialogCountriesVisible = true;
        },
        handleDeleteCountries(bank) {
            this.creationContainer.banksData.deleteBank(bank);
        },
        handleSelectCountries(bank) {
            this.selectedCountries = [];
            this.selectCountriesValue = bank;
            this.dialogCountriesVisible = true;
        },
        handleConfirmCountries(selectedCountries) {
            this.creationContainer.banksData.selectBank(this.selectCountriesValue, selectedCountries);
        },
        handleNextStep() {
            this.creationContainer.handleSelectBanks();
        }
    },
}
</script>
<style lang="scss">
#sa-new-feedback-s1 {
    margin-top: 41px;
    .el-pagination__total, .el-pagination__sizes, .el-pagination__jump {
        display: none !important;
    }
    .sa-company-banks {
        &__title {
            font-weight: 700;
            font-size: 16px;
            line-height: 13px;
            color: #515151;
            margin-bottom: 23px;
            span {
                background: #ECECEC;
                border-radius: 3px;
                font-weight: 600;
                font-size: 12px;
                line-height: 15px;
                text-align: center;
                color: #000000;
                padding: 2px 5px;
                margin-left: 8px;
            }
        }
        &__card {
            background: #FFFFFF;
            box-shadow: 0px 9px 29px rgba(0, 0, 0, 0.06);
            border-radius: 12px;
            padding-top: 26px;
            &--search {
                position: relative;
                background: #FAFAFA;
                border-radius: 29px;
                margin: 0 21px;
                margin-bottom: 17px;
                img {
                    position: absolute;
                    left: 24px;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 10px;
                }
                .el-input__inner {
                    background: transparent;
                    border: none;
                    height: 42px;
                    padding-left: 45px;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    &::placeholder {
                        color: #C4C4C4;
                    }
                }
            }
            &--banks {
                .no-banks-found {
                    padding: 15px 29px;
                    text-align: center;
                }
                .sa-banks-item {
                    display: flex;
                    justify-content: space-between;
                    padding: 15px 29px;
                    position: relative;
                    overflow: hidden;
                    cursor: pointer;
                    transition: .2s ease-in-out;
                    &__name {
                        display: flex;
                        align-items: center;
                        width: calc(100% - 18px);
                        img {
                            width: 24px;
                            height: 24px;
                            border-radius: 50%;
                            object-fit: cover;
                            object-position: center;
                            margin-right: 15px;
                        }
                        span {
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 158.02%;
                            color: #2B2B2B;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                    &:not(:last-child):after {
                        content: "";
                        position: absolute;
                        background: rgba(0, 0, 0, 0.1);
                        bottom: 0;
                        left: 71px;
                        width: 200%;
                        height: 1px;
                    }
                    &:hover {
                        background: #f9f9f9;
                    }
                    &.is-selected {
                        background: rgba(99, 170, 252, 0.08);
                        cursor: default;
                    }
                }
            }
        }
    }
    .sa-selected-banks {
        &__header {
            display: flex;
            justify-content: space-between;
            padding: 0 15px;
            &--title {
                font-weight: 700;
                font-size: 16px;
                line-height: 13px;
                color: #52525280;
                display: flex;
                align-items: center;
                span {
                    background: #ECECEC;
                    border-radius: 3px;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 15px;
                    text-align: center;
                    color: #000000;
                    padding: 2px 5px;
                    margin-left: 8px;
                }
            }
            a {
                font-weight: 400;
                font-size: 12px;
                line-height: 158.02%;
                color: #EA3333;
            }
        }
        .no-selected {
            background: #F9F9F9;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 17px;
            margin-top: 22px;
            padding: 59px 20px 50px;
            display: flex;
            flex-direction: column;
            align-items: center;
            h4 {
                font-weight: 700;
                font-size: 18px;
                line-height: 26px;
                color: #000000;
                opacity: 0.87;
                margin-top: 20px;
            }
            p {
                font-weight: 300;
                font-size: 16px;
                line-height: 19px;
                color: #737887;
                margin-bottom: 0;
            }
        }
        &__cards {
            background: #F9F9F9;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 17px;
            padding: 12px 0;
            margin-top: 22px;
            max-height: 740px;
            overflow-y: auto;
            .selected-item {
                padding: 20px 24px;
                position: relative;
                &:not(:last-child):after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 20px;
                    right: 0;
                    height: 1px;
                    background: rgba(0, 0, 0, 0.05);;
                }
                &__header {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 10px;
                    &--name {
                        display: flex;
                        align-items: center;
                        img {
                            width: 20px;
                            height: 20px;
                            border-radius: 50%;
                            object-fit: cover;
                            object-position: center;
                            margin-right: 12px;
                        }
                        span {
                            font-weight: 600;
                            font-size: 16px;
                            line-height: 158.02%;
                            color: #000000;
                        }
                    }
                    &--actions {
                        display: flex;
                        gap: 10px;
                        button {
                            background: transparent;
                            border-radius: 5px;
                            border: none;
                            transition: .2s ease-in-out;
                            img {
                                filter: invert(38%) sepia(5%) saturate(9%) hue-rotate(123deg) brightness(90%) contrast(89%);
                            }
                            &:hover {
                                background: #eaeaea;
                            }
                        }
                    }
                }
                p {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 15px;
                    color: #666666;
                    margin-bottom: 12px;
                }
                &__countries {
                    display: flex;
                    gap: 8px;
                    overflow-x: auto;
                    span {
                        background: #FFFFFF;
                        border: 1px solid rgba(0, 0, 0, 0.05);
                        border-radius: 5px;
                        display: inline-block;
                        padding: 9px 11px;
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 15px;
                        color: #000000;
                        white-space: pre;
                    }
                }
            }
        }
    }
}
.sa-bank-country-modal {
    &__info {
        display: flex;
        align-items: center;
        margin-bottom: 28px;
        img {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            object-fit: cover;
            object-position: center;
            margin-right: 16px;
        }
        span {
            font-weight: 600;
            font-size: 16px;
            line-height: 158.02%;
            color: #2B2B2B;
        }
    }
}

@media (max-width: 768px) {
    #sa-new-feedback-s1 {
        .sa-selected-banks {
            margin-top: 25px;
        }
    }
}
</style>
