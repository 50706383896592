<template>
    <div class="sa-new-feedback__card" id="sa-new-feedback-s5" v-if="creationContainer.customersData">
        <div class="quick-info">
            <div class="quick-info__title">
                {{ $t('Quick Info') }}
            </div>
            <div class="quick-info__section">
                <div class="quick-info__section--list">
                    <div class="quick-info__section--list--count">{{ creationContainer.customersData.banks.length }}</div>
                    <div class="quick-info__section--list--title">{{ $t('Banks Included') }}:</div>
                    <div class="quick-info__section--list--item">
                        <div class="list-item" v-for="(item, index) in creationContainer.customersData.banks.slice(0, limitSize)" :key="index">
                            {{ item.name }}
                        </div>
                        <div v-if="creationContainer.customersData.banks.length > limitSize" class="list-item">
                            +{{ creationContainer.customersData.banks.length - limitSize }}
                        </div>
                    </div>
                </div>
                <div class="quick-info__section--list">
                    <div class="quick-info__section--list--count">{{ creationContainer.customersData.productAreas.length }}</div>
                    <div class="quick-info__section--list--title">{{ $t('Product Areas Included') }}:</div>
                    <div class="quick-info__section--list--item">
                        <div class="list-item" v-for="(item, index) in creationContainer.customersData.productAreas.slice(0, limitSize)" :key="index">
                            {{ item }}
                        </div>
                        <div v-if="creationContainer.customersData.productAreas.length > limitSize" class="list-item">
                            +{{ creationContainer.customersData.productAreas.length - limitSize }}
                        </div>
                    </div>
                </div>
                <div class="quick-info__section--list">
                    <div class="quick-info__section--list--count">{{ creationContainer.customersData.countries.length }}</div>
                    <div class="quick-info__section--list--title">{{ $t('Countries Included') }}:</div>
                    <div class="quick-info__section--list--item">
                        <div class="list-item" v-for="(item, index) in creationContainer.customersData.countries.slice(0, limitSize)" :key="index">
                            {{ item }}
                        </div>
                        <div v-if="creationContainer.customersData.countries.length > limitSize" class="list-item">
                            +{{ creationContainer.customersData.countries.length - limitSize }}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <SASelectCustomerSection :customersData="creationContainer.customersData" :creationContainer="creationContainer"/>

        <div class="w-100 d-flex justify-content-end mt-10">
            <button
                :data-kt-indicator="loading ? 'on' : 'off'"
                :disabled="loading"
                class="main-btn d-block btn continue-button"
                @click.prevent="handleNextStep()"
            >
                <span class="indicator-label"> {{ $t('Continue') }}</span>

                <span class="indicator-progress">
                      {{ $t("pleaseWait") }}
                      <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
            </button>
        </div>
    </div>
</template>

<script>
import { SuperAdminFeedbackCreationContainer } from "@/store/models/super-admin/SuperAdminFeedbackCreationContainer";
import SASelectCustomerSection from "@/buying-teams/pages/super-admin/feedbacks/feedbacks-creation/SASelectCustomerSection";
import store from "@/store";

export default {
    name: "SAStepFiveSelectCustomers",
    components: {SASelectCustomerSection},
    props: {
        creationContainer: [Object, SuperAdminFeedbackCreationContainer]
    },
    data() {
        return {
            limitSize: 6,
            loading: false,
        }
    },
    methods: {
        handleNextStep() {
            let payloadData = this.creationContainer.customersData.getAllSelectedUsers;
            if (payloadData.length) {
                this.loading = true;
                store.dispatch('bankAddParticipants', {
                    users: payloadData,
                    survey_id: this.creationContainer.feedbackSessionPayload.surveyId
                }).then(() => {
                    this.creationContainer.handleSelectCustomers();
                    this.creationContainer.nextStep();
                }).finally(() => {
                    this.loading = false;
                })
            }
        },
    },
}
</script>

<style lang="scss">
#sa-new-feedback-s5 {
    margin-top: 41px;
    .quick-info {
        &__title {
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            color: #4F4F4F;
            margin-left: 10px;
            margin-bottom: 8px;
        }
        &__section {
            background: #F8F8F8;
            border-radius: 7px;
            &--title {
                font-weight: 500;
                font-size: 24px;
                line-height: 170.02%;
                color: #515151;
                opacity: 0.9;
                padding: 18px 16px 15px 16px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.1);
            }
            &--list {
                display: flex;
                align-items: center;
                width: 100%;
                padding: 15px 17px;
                &:not(:last-child) {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                }
                &--count {
                    background: #FFFFFF;
                    border: 2px solid #435BF4;
                    border-radius: 6px;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 158.02%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #435BF4;
                    height: 34px;
                    min-width: 34px;
                    margin-right: 9px;
                }
                &--title {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 158.02%;
                    color: #000000;
                    min-width: 140px;
                }
                &--item {
                    display: flex;
                    margin-left: 8px;
                    flex-wrap: wrap;
                    margin-bottom: -8px;

                    .list-item {
                        background: #FFFFFF;
                        border-radius: 5px;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 15px;
                        color: #000000;
                        padding: 8px;
                        margin: 0 8px;
                        margin-bottom: 8px;
                    }
                }
            }
        }
    }

    @media (max-width: 768px) {
        .quick-info__section--list {
            flex-wrap: wrap;
            .quick-info__section--list--item {
                width: 100%;
                margin-left: 0;
                margin-top: 8px;
                gap: 8px;
                .list-item {
                    margin: 0;
                }
            }
        }
    }
}
</style>
