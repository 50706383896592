<template>
    <el-dialog
        v-model="visibility"
        center
        width="1200px"
        :show-close="false"
        @close="closeModal"
        append-to-body
        custom-class="sa-customer-modal"
    >
        <template #title>
            <div class="sa-customer-modal__header">
                <div class="sa-customer-modal__header--name">
                    <img :src="customerModalData.iconPath" alt="">
                    <h1>
                        <span>{{ customerModalData.businessName }}</span>
                        <div class="customer-status"
                             :class="customerModalData.isRegistered ? 'registered-customer' : 'not-registered'">
                            <inline-svg src="/media/buying/icons/customer-status.svg"></inline-svg>
                            <span>{{ customerModalData.isRegistered ? $t('Registered Customer') : $t('Not Registered') }}</span>
                        </div>
                    </h1>
                </div>
                <div class="sa-customer-modal__header--add-user">
                    <el-form
                        ref="addEmail"
                        :model="addEmailsForm"
                        :rules="addEmailsRules"
                    >
                        <el-form-item prop="email">
                            <el-input v-model="addEmailsForm.email" :placeholder="$t('Add Email')"></el-input>
                        </el-form-item>
                        <button
                            class="sa-customer-modal__header--add-user__btn"
                            @click.prevent="handleAddEmails"
                            :disabled="emailAddLoading"
                        >
                            <CustomLoader hide-text v-if="emailAddLoading"/>
                            <img src="/media/buying/icons/plus.svg" width="15" alt="" v-else>
                        </button>
                    </el-form>
                </div>
            </div>
        </template>

        <div class="sa-customer-modal__body">
            <div class="sa-customer-modal__body--top">
                <el-checkbox
                    v-model="checkAll"
                    @change="handleCheckAllChange"
                    class="select-all"
                >{{ $t('Select all') }}
                </el-checkbox>

                <div class="counts-section">
                    <img src="/media/buying/icons/users-icon.svg" alt="">
                    <span>{{ checkedUsers.length }}/{{ customerModalData.users.length }}</span>
                </div>
            </div>

            <div class="sa-customer-modal__body--content">
                <el-checkbox-group
                    v-model="checkedUsers"
                    @change="handleCheckedUsersChange"
                >
                    <div
                        class="content-list"
                        v-for="item in customerModalData.users"
                        :key="item"
                        @click="handleClickCheckBox(item)"
                        :class="{'active-item': checkedUsers.includes(item.userId)}"
                    >
                        <el-checkbox
                            :label="item.userId"
                            :disabled="!item.selectedCountries.length || !item.selectedProductAreas.length"
                        >
                            <div class="content-list__info">
                                <h3>
                                    <span>{{ item.firstName }} {{ item.lastName }}</span>
<!--                                    <strong v-if="item.isMockUser">{{ $t('Manually added - overriding user settings') }}</strong>-->
                                </h3>
                                <div class="content-list__info--list">
                                    <div class="info-list-item">
                                        <img src="/media/buying/icons/user-default.svg" alt="">
                                        <span>{{ item.jobTitle }}</span>
                                    </div>
                                    <div class="info-list-item">
                                        <img src="/media/buying/icons/map-location.svg" alt="">
                                        <span>{{ item.country }}</span>
                                    </div>
                                </div>
                            </div>
                        </el-checkbox>

                        <div class="content-list__error">
                            <div v-if="errorsObj[item.userId] && (!item.selectedCountries.length || !item.selectedProductAreas.length || !item.selectedBanks.length)">
                                {{ getErrorMessage(item) }}
                            </div>
                        </div>

                        <div class="content-list__right">
                            <div class="content-list__right--item" v-if="customersData.banks.length">
                                <h4>{{ $t('Banks Included') }}:</h4>

                                <CustomSelfGroupSelect
                                    v-model="item.selectedBanks"
                                    @change="handleChange(item)"
                                    :options-data="customersData.banks"
                                    :error="!item.selectedBanks.length"
                                />
                            </div>
                            <div class="content-list__right--item" v-if="customersData.productAreas.length">
                                <h4>{{ $t('Product Areas Match') }}:</h4>

                                <CustomGroupSelect
                                    v-model="item.selectedProductAreas"
                                    @change="handleChange(item)"
                                    :options-data="productAreasOptions"
                                    :error="!item.selectedProductAreas.length"
                                />
                            </div>
                            <div class="content-list__right--item" v-if="customersData.countries.length">
                                <h4>{{ $t('Country Match') }}:</h4>

                                <CustomGroupSelect
                                    v-model="item.selectedCountries"
                                    @change="handleChange(item)"
                                    :options-data="countriesOptions"
                                    :error="!item.selectedCountries.length"
                                />
                            </div>
                        </div>
                    </div>
                </el-checkbox-group>
            </div>

            <div class="buttons-wrapper">
                <button class="main-btn d-block btn" @click="closeModal">
                    <span class="indicator-label"> {{ $t('Cancel') }}</span>
                </button>
                <button class="main-btn d-block btn" @click="saveUsers">
                    <span class="indicator-label"> {{ $t('Save') }}</span>
                </button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import {
    SACCBusinessModel,
    SuperAdminSelectCustomersContainer
} from "@/store/models/bank/feedback/SuperAdminSelectCustomersContainer";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";
import CustomGroupSelect from "@/buying-teams/pages/bank/feedbacks/components/CustomGroupSelect";
import CustomSelfGroupSelect from "@/buying-teams/pages/bank/feedbacks/components/CustomSelfGroupSelect";

export default {
    name: "SACustomerModal",
    components: {
        CustomSelfGroupSelect,
        CustomGroupSelect,
        CustomLoader
    },
    props: {
        visibility: Boolean,
        customerModalData: [Object, SACCBusinessModel],
        customersData: [Object, SuperAdminSelectCustomersContainer],
        emailErrorMsg: String,
        emailAddLoading: {
            type: Boolean,
            default: false
        }
    },
    emits: ['onAddUserByEmail'],
    data() {
        return {
            checkAll: false,
            isIndeterminate: false,
            checkedUsers: [],
            errorsObj: {},
            addEmailsForm: {
                email: "",
            },
        }
    },
    methods: {
        handleCheckAllChange(val) {
            this.checkedUsers = val ? this.customerModalData.users.map(u => {
                if (u.selectedCountries.length && u.selectedProductAreas.length) return u.userId
                else {
                    this.errorsObj[u.userId] = true;
                }
            }).filter(u => !!u) : [];
            this.checkAll = !!this.checkedUsers.length;
            this.isIndeterminate = false
        },
        handleAddEmails() {
            this.$emit('onAddUserByEmail', { email: this.addEmailsForm.email, emailFormRef: this.$refs.addEmail });
        },
        saveUsers() {
            const checkCustomerExist = this.customersData.checkCustomerExist(this.customerModalData.businessId);
            if (!checkCustomerExist) {
                this.customersData.allCustomers.push(this.customerModalData);
            }

            this.customerModalData.addUsers(this.checkedUsers);
            this.$emit('close');
        },
        handleCheckedUsersChange(value) {
            const checkedCount = value.length
            this.checkAll = checkedCount === this.customerModalData.users.length
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.customerModalData.users.length
        },
        handleClickCheckBox(item) {
            if (!item.selectedCountries.length || !item.selectedProductAreas.length || !item.selectedBanks.length) {
                this.errorsObj[item.userId] = true;
            }
        },
        handleChange(item) {
            if (!item.selectedCountries.length || !item.selectedProductAreas.length || !item.selectedBanks.length) {
                this.checkedUsers.forEach((u, index) => {
                    if (item.userId === u) {
                        this.checkedUsers.splice(index, 1)
                    }
                })
            } else {
                if (!this.checkedUsers.includes(item.userId)) {
                    this.checkedUsers.push(item.userId)
                }
            }
            this.checkAll = this.checkedUsers.length === this.customerModalData.users.length
        },
        closeModal() {
            this.customerModalData.cancelChanges([])
            this.$emit('close');
        },
        getErrorMessage(item) {
            let message = '';
            if (!item.selectedBanks.length && !item.selectedProductAreas.length && !item.selectedCountries.length) {
                message = this.$t('You need to select a minimum of one country, product area and bank')
            } else {
                message = this.$t('You need to select a minimum of one', {
                    name: `${!item.selectedBanks.length ? 'bank' : ''} ${!item.selectedCountries.length ? 'country' : ''} ${!item.selectedProductAreas.length ? 'product area' : ''}`
                })
            }


            return message
        }
    },
    computed: {
        addEmailsRules() {
            var validateEmailExists = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error(this.$t('Please input Email')));
                } else {
                    if (this.emailErrorMsg) {
                        callback(new Error(this.emailErrorMsg));
                    }
                    callback();
                }
            };
            return {
                email: [
                    {
                        required: true,
                        message: this.$t("Please input Email"),
                        trigger: "change"
                    },
                    {
                        required: true,
                        type: "email",
                        message: this.$t("Please input correct email"),
                        trigger: "change"
                    },
                    {
                        validator: validateEmailExists,
                        trigger: 'change'
                    },
                ]
            }
        },
        productAreasOptions() {
            return this.customersData.productAreas.map(pa => { return { label: pa, value: pa }})
        },
        countriesOptions() {
            return this.customersData.countries.map(c => { return { label: c, value: c }})
        },
    },
    created() {
        this.checkedUsers = this.customerModalData.matchedSelectedUsers.map(u => u.userId);
        if (this.customerModalData.users.length === this.checkedUsers.length) {
            this.checkAll = true
        }
    },
    watch: {
        emailErrorMsg() {
            this.$refs.addEmail.validate();
        }
    }
}
</script>

<style lang="scss">
.sa-customer-modal {
    box-shadow: 0 9px 29px rgba(0, 0, 0, 0.06);
    border-radius: 12px;

    .el-checkbox-group {
        font-size: inherit;
    }

    &__header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        &--name {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            img {
                width: 38px;
                height: 38px;
                border-radius: 50%;
                margin-right: 19px;
            }

            h1 {
                display: flex;
                flex-direction: column;
                margin-bottom: 0;

                span {
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 178.52%;
                    color: #000000;
                    opacity: 0.8;
                    margin-bottom: 0;
                    display: flex;
                }

                .customer-status {
                    display: flex;

                    span {
                        font-weight: 500;
                        font-size: 10px;
                        line-height: 158.02%;
                        margin-left: 4px;
                    }

                    &.registered-customer {
                        path {
                            fill: #2BC490;
                        }

                        span {
                            color: #2BC490;
                        }
                    }

                    &.not-registered {
                        path {
                            fill: #C4C4C4;
                        }

                        span {
                            color: #C4C4C4;
                        }
                    }
                }
            }
        }

        &--add-user {
            margin-bottom: -22px;
            min-width: 560px;

            .el-input__inner {
                background: #F8F8F8;
                box-shadow: inset 0 1.12463px 4.4985px rgba(0, 0, 0, 0.05);
                border-radius: 5.28234px;
                height: 56px;
                border-color: transparent;

                &::placeholder {
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 17px;
                    color: #000000;
                    opacity: 0.5;
                }
            }

            .el-form-item.is-error .el-input__inner {
                border-color: #f56c6c;
            }

            .el-input__inner {
                &::placeholder {
                    text-transform: uppercase;
                }
            }

            .el-form {
                display: flex;
                width: 100%;
                gap: 10px;

                .el-form-item {
                    width: 100%;
                }
            }

            &__btn {
                background: rgba(67, 91, 244, 0.05);
                border-radius: 5.28234px;
                width: 68px;
                height: 56px;
                border: none;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: .3s ease-in-out;

                .content-loader > div {
                    margin-top: 0 !important;
                }

                &:not(:disabled):hover {
                    background: #e9ebff;
                }

                &:disabled {
                    opacity: .5;
                }
            }

            .added-emails {
                display: flex;
                flex-direction: column;
                gap: 2px;
                margin-bottom: 24px;

                &__title {
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 13px;
                    color: #515151;
                    margin-bottom: 12px;
                    margin-top: 10px;
                    margin-left: 13px;

                }

                &__item {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    height: 66px;
                    padding: 13px;
                    border-radius: 8px;

                    span {
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 19px;
                        color: #3E3E3E;
                    }

                    &:nth-child(odd) {
                        background: #FAFAFC;
                    }

                    button {
                        background: transparent;
                        border: 0;
                        padding: 0;

                        img {
                            transition: .3s ease-in-out;
                            filter: invert(34%) sepia(12%) saturate(28%) hue-rotate(316deg) brightness(89%) contrast(82%);
                        }

                        &:hover {
                            img {
                                filter: invert(22%) sepia(61%) saturate(4166%) hue-rotate(352deg) brightness(93%) contrast(90%);
                            }
                        }
                    }
                }
            }
        }
    }

    .el-dialog__header {
        padding: 30px 29px;
    }

    .el-dialog__body {
        padding: 0;
    }

    &__body {
        .buttons-wrapper {
            padding: 28px;
            display: flex;
            justify-content: center;
            gap: 20px;

            button.main-btn {
                min-width: 144px;
                height: 51px;
                border: 1px solid #435BF4 !important;

                &:first-child {
                    background-color: transparent !important;
                    color: #435BF4 !important;
                }
            }
        }

        .el-checkbox__inner {
            width: 20px;
            height: 20px;
            border-radius: 50%;
        }

        .el-checkbox__inner::after {
            left: 7px;
            top: 3px;
        }

        .el-checkbox__input.is-checked .el-checkbox__inner::after {
            transform: rotate(45deg) scaleY(1.3);
        }

        &--top {
            padding: 12px 38px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .counts-section {
                display: flex;
                align-items: center;

                img {
                    width: 22px;
                    margin-right: 10px;
                }

                span {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 158.02%;
                    color: #2A2A2A;
                }
            }

            .select-all {
                .el-checkbox__input.is-indeterminate .el-checkbox__inner::before {
                    top: 7px;
                }

                .el-checkbox__label {
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 19px;
                    display: flex;
                    align-items: flex-end;
                    color: #000000;
                    padding-left: 28px;
                }
            }
        }

        &--content {
            .content-list {
                padding: 17px 36px 13px 38px;
                border-top: 1px solid rgba(0, 0, 0, 0.1);
                display: flex;
                align-items: center;
                justify-content: space-between;

                &:last-child {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                }

                &.active-item {
                    background: #EEF6FF;
                }

                .el-checkbox__input.is-disabled + span.el-checkbox__label {
                    cursor: pointer;
                }

                .el-checkbox__input.is-disabled .el-checkbox__inner {
                    background-color: #ffffff;
                }

                .el-checkbox {
                    display: inline-flex;
                    height: auto;
                    min-width: 300px;
                }

                .el-checkbox__label {
                    padding-left: 28px;
                    display: inline-flex;
                }

                &__info {
                    h3 {
                        display: flex;
                        align-items: center;
                        margin-bottom: 20px;

                        span {
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 19px;
                            color: #000000;
                            display: flex;
                            align-items: center;
                        }

                        strong {
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 158.02%;
                            color: #435BF4;
                            margin-left: 16px;
                        }
                    }

                    &--list {
                        display: flex;
                        align-items: center;

                        .info-list-item {
                            display: flex;
                            align-items: center;

                            img {
                                margin-right: 9px;
                            }

                            span {
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 19px;
                                color: #8B8B8B;
                            }

                            &:not(:last-child) {
                                margin-right: 28px;
                            }
                        }
                    }
                }

                &__error {
                    padding: 0 20px;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 158.02%;
                    color: #FF5959;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    max-width: 420px;
                    margin-left: auto;
                    word-break: break-word;
                }

                &__right {
                    display: flex;

                    .el-select__tags {
                        display: none;
                    }

                    .el-select {
                        .el-input {
                            padding-right: 35px;
                            padding-left: 12px;
                            background: #FFFFFF;
                            border: 2px solid #435BF4;
                            border-radius: 6px;
                            height: 41px;
                            cursor: pointer;

                            .el-select__caret {
                                color: #435BF4;
                            }
                        }

                        .el-input__inner {
                            display: none;
                        }

                        .el-input__prefix {
                            position: revert;
                            font-weight: 700;
                            font-size: 16px;
                            line-height: 158.02%;
                            color: #435BF4;
                        }

                        &.is-error {
                            .el-input {
                                border: 2px solid rgba(67, 91, 244, 0.16);
                            }

                            .el-input__prefix {
                                color: #FF5959;
                            }
                        }
                    }

                    &--item {
                        h4 {
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 158.02%;
                            color: #000000;
                            margin-bottom: 8px;
                            white-space: nowrap;
                        }

                        &:nth-child(2), &:nth-child(3) {
                            padding-left: 28px;
                            margin-left: 47px;
                            border-left: 1px solid rgba(0, 0, 0, 0.1);
                        }
                    }
                }
            }
        }
    }
}
.swal2-container {
    z-index: 99999;
}
</style>
