<template>
    <div class="sa-customer-selection">
        <div class="sa-customer-selection__all">
            <div class="sa-customer-selection__header">
                <div class="sa-customer-selection__title">
                    <span>{{ $t('All Customers') }}</span>
                    <strong>{{ customersData.allCustomers.length }}</strong>
                </div>
            </div>
            <div class="sa-customer-selection__all--card">
                <div class="sa-customer-selection__search">
                    <inline-svg src="/media/buying/icons/search.svg"></inline-svg>
                    <input type="text" v-model="search" @input="allCustomersPage = 1" :placeholder="$t('Search Customers')">
                </div>

                <button class="select-all-btn" @click="handleSelectAll">{{ $t('Select All') }}</button>

                <div class="sa-customer-selection__scrollbar" v-if="allCustomersDisplayData.length">
                    <template v-for="(item, index) in allCustomersDisplayData" :key="index">
                        <SAAllCustomersItem
                            :item="item"
                            :customersData="customersData"
                            @onClickItem="openCustomerModel(item)"
                        />
                        <div class="card-divider"></div>
                    </template>
                </div>

                <div class="sa-customer-selection__no-data mt-0 pb-14" v-else>
                    <img src="/media/buying/no-data-list.svg" alt="">
                    <h4>{{ $t('No Data Found') }}</h4>
                </div>
            </div>

            <CustomPagination
                class="mt-3"
                :current-page="allCustomersPage"
                :total="customersData.getFilteredData(search).length"
                :page-size="allCustomersPageSize"
                :hide-on-single-page="false"
                @currentPageChange="allCustomersPage = $event"
                @pageSizeChange="allCustomersPageSize = $event"
            />
        </div>
        <div class="sa-customer-selection__selected">
            <div class="sa-customer-selection__header">
                <div class="sa-customer-selection__title">
                    <span>{{ $t('Selected Customers') }}</span>
                    <strong>{{ customersData.selectedCustomers.length }}</strong>
                </div>
                <div class="sa-customer-selection__header--right">
                    <button class="add-customer-logo" @click="showAddCustomerModal = true">
                        <img src="/media/buying/icons/mail-outline-icon.svg" alt="">
                        {{ $t('Add Customer by Email') }}
                    </button>
                    <button class="clear-all-selection" @click="handleClearAll">
                        {{ $t('Clear All') }}
                    </button>
                </div>
            </div>

            <div class="sa-customer-selection__selected--card">
                <div class="sa-customer-selection__scrollbar" v-if="selectedCustomersDisplayData.length">
                    <template v-for="(item, index) in selectedCustomersDisplayData" :key="index">
                        <SASelectedCustomersItem
                            :item="item"
                            :customersData="customersData"
                            @onClickItem="openCustomerModel(item)"
                            @onRemove="handleUnselect(item)"
                        />
                        <div class="card-divider"></div>
                    </template>
                </div>
                <div class="sa-customer-selection__no-data" v-else>
                    <img src="/media/buying/no-data-list.svg" alt="">
                    <h4>{{ $t('select customer\'s\'') }}</h4>
                    <p>{{ $t('You need to select at least one customer to continue') }}</p>
                </div>
            </div>

            <CustomPagination
                class="mt-3"
                :current-page="selectedCustomersPage"
                :total="customersData.selectedCustomers.length"
                :page-size="selectedCustomersPageSize"
                :hide-on-single-page="false"
                @currentPageChange="selectedCustomersPage = $event"
                @pageSizeChange="selectedCustomersPageSize = $event"
            />
        </div>

        <SACustomerModal
            v-if="showCustomerModal"
            :visibility="showCustomerModal"
            :customerModalData="customerModalData"
            :customersData="customersData"
            :emailErrorMsg="emailErrorMsg"
            :emailAddLoading="emailAddLoading"
            @onAddUserByEmail="handleAddUserByEmail"
            @close="showCustomerModal = false"
        />

        <AddCustomerModal
            ref="addCustomerModal"
            v-if="showAddCustomerModal"
            :visibility="showAddCustomerModal"
            :customersData="customersData"
            @onClose="showAddCustomerModal = false"
            @onAddNewCustomer="handleAddNewCustomer"
            @onOpenCustomerPopup="handleOpenCustomerModel"
        />
    </div>
</template>

<script>
import store from "@/store";
import { defineComponent } from "vue";
import { Constants } from "@/core/config/constants";
import SAAllCustomersItem from "@/buying-teams/pages/super-admin/feedbacks/feedbacks-creation/SAAllCustomersItem";
import SASelectedCustomersItem from "@/buying-teams/pages/super-admin/feedbacks/feedbacks-creation/SASelectedCustomersItem";
import SACustomerModal from "@/buying-teams/pages/super-admin/feedbacks/feedbacks-creation/SACustomerModal";
import AddCustomerModal from "@/buying-teams/pages/bank/feedbacks/feedbacks-creation/AddCustomerModal";
import { Actions } from "@/store/enums/StoreEnums";
import { SuperAdminSelectCustomersContainer } from "@/store/models/bank/feedback/SuperAdminSelectCustomersContainer";
import { SuperAdminFeedbackCreationContainer } from "@/store/models/super-admin/SuperAdminFeedbackCreationContainer";
import CustomPagination from "@/buying-teams/shared-components/utils/CustomPagination.vue";

export default defineComponent({
    name: "SASelectCustomerSection",
    components: {
        CustomPagination,
        AddCustomerModal,
        SACustomerModal,
        SASelectedCustomersItem,
        SAAllCustomersItem
    },
    props: {
        customersData: [Object, SuperAdminSelectCustomersContainer],
        creationContainer: [Object, SuperAdminFeedbackCreationContainer]
    },
    emits: ['handleTabChange'],
    data() {
        return {
            Constants,
            showCustomerModal: false,
            customerModalData: {},
            showAddCustomerModal: false,
            search: '',
            emailErrorMsg: '',
            emailAddLoading: false,
            allCustomersPage: 1,
            allCustomersPageSize: 6,
            selectedCustomersPage: 1,
            selectedCustomersPageSize: 7,
        }
    },
    computed: {
        allCustomersDisplayData() {
            return this.customersData.getFilteredData(this.search).slice(this.allCustomersPageSize * this.allCustomersPage - this.allCustomersPageSize, this.allCustomersPageSize * this.allCustomersPage);
        },
        selectedCustomersDisplayData() {
            return this.customersData.selectedCustomers.slice(this.selectedCustomersPageSize * this.selectedCustomersPage - this.selectedCustomersPageSize, this.selectedCustomersPageSize * this.selectedCustomersPage);
        },
    },
    methods: {
        openCustomerModel(item) {
            this.showAddCustomerModal = false;
            this.showCustomerModal = true;
            this.customerModalData = item;
        },
        handleOpenCustomerModel(customerId) {
            const checkCustomerExist = this.customersData.checkCustomerExist(customerId);
            if (checkCustomerExist) {
                this.openCustomerModel(checkCustomerExist);
            } else {
                store.dispatch('getBusinessDetails', { business_id: customerId })
                    .then((res) => {
                        let business = this.customersData.createNewBusinessModelData(res);
                        this.openCustomerModel(business);
                    })
            }
        },
        handleUnselect(item) {
            item.isSelected = false;
        },
        handleSelectAll() {
            this.customersData.selectAll();
        },
        handleClearAll() {
            this.customersData.deselectAll();
        },
        handleAddUserByEmail({email, emailFormRef}) {
            this.emailErrorMsg = '';
            emailFormRef.validate((valid) => {
                if (valid) {
                    this.emailAddLoading = true;
                    store.dispatch(Actions.CHECK_EMAIL, { email }).then( async (res) => {
                        if (!res.data.data.is_registered_user) {
                            await store.dispatch('businessAddByEmail', {
                                bank_ids: this.creationContainer.feedbackSessionPayload.selectedBanks.map(b => b.id),
                                business_id: this.customerModalData.businessId,
                                email,
                                countries: this.customersData.countries,
                                product_areas: this.customersData.productAreas,
                            }).then(res => {
                                if (res.user) {
                                    this.customerModalData.addNewUser(res.user, this.customersData.banks);
                                }
                            })
                        } else {
                            this.emailErrorMsg = this.$t('The email you are trying to add here already exists.');
                        }

                        this.emailAddLoading = false;
                    }).catch(() => {
                        this.emailErrorMsg = this.$t('Something went wrong.');
                        this.emailAddLoading = false;
                    })
                }
            })
        },
        handleAddNewCustomer(payload) {
            this.$refs.addCustomerModal.submitButtonLoading = true;
            store.dispatch('bankFeedbackAddCustomer', {
                survey_id: this.customersData.surveyId,
                business_name: payload.companyName,
                emails: payload.emails,
                countries: this.customersData.countries,
                product_areas: this.customersData.productAreas,
                bank_ids: this.creationContainer.feedbackSessionPayload.selectedBanks.map(b => b.id),
            }).then(res => {
                this.customersData.addNewCustomers({
                    companyName: payload.companyName,
                    users: res.data.data.users,
                    businessId: res.data.data.business.id
                });
                this.showAddCustomerModal = false;
            }).catch(() => {
                this.$refs.addCustomerModal.submitButtonLoading = false;
            });
        }
    },
})
</script>

<style lang="scss">
.sa-customer-selection {
    display: flex;
    margin-top: 30px;
    .el-pagination__total, .el-pagination__sizes, .el-pagination__jump {
        display: none !important;
    }
    &__header {
        margin-bottom: 18px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 40px;
        &--right {
            display: flex;
            align-items: center;
            gap: 19px;
        }
    }
    .add-customer-logo {
        background: #F6F7FF;
        border-radius: 29px;
        padding: 10px 18px;
        border: none;
        font-weight: 600;
        font-size: 12px;
        line-height: 158.02%;
        color: #435BF4;
        display: flex;
        align-items: center;
        transition: .3s ease-in-out;
        z-index: 1;

        img {
            margin-right: 8px;
            filter: invert(22%) sepia(100%) saturate(1618%) hue-rotate(222deg) brightness(118%) contrast(93%);
        }

        &:hover {
            background: #e9ebff;
        }
    }
    .clear-all-selection {
        font-weight: 400;
        font-size: 12px;
        line-height: 158.02%;
        color: #EA3333;
        background: none;
        border: none;
        padding: 0;
    }
    .card-divider {
        width: 100%;
        height: 1px;
        background: #0000001A;
    }
    &__title {
        display: flex;
        align-items: center;
        span {
            font-weight: 700;
            font-size: 16px;
            line-height: 13px;
            color: #515151;
            margin-right: 6px;
        }
        strong {
            background: #ECECEC;
            border-radius: 3px;
            padding: 2px 10px;
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            color: #000000;
        }
    }
    &__all {
        width: 45%;
        padding-right: 13px;
        &--card {
            background: #FFFFFF;
            box-shadow: 0 9px 29px rgba(0, 0, 0, 0.06);
            border-radius: 12px;
            padding-top: 26px;
        }
    }
    &__selected {
        width: 55%;
        padding-left: 13px;
        &--card {
            background: #F9F9F9;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 17px;
            height: calc(100% - 121px);
            .sa-customer-selection__scrollbar {
                max-height: 886px;
            }
        }
    }
    &__scrollbar {
        max-height: 740px;
        overflow-y: initial;
        .card-divider:last-child {
            display: none;
        }
    }
    &__no-data {
        margin-top: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        h4 {
            font-weight: 700;
            font-size: 18px;
            line-height: 26px;
            color: #000000;
            opacity: 0.87;
            text-transform: uppercase;
            margin-bottom: 10px;
            margin-top: 10px;
        }
        p {
            font-weight: 300;
            font-size: 16px;
            line-height: 19px;
            color: #737887;
        }
    }
    &__search {
        position: relative;
        background: #FAFAFA;
        border-radius: 29px;
        height: 42px;
        margin: 0 21px;
        margin-bottom: 35px;
        svg {
            position: absolute;
            left: 20px;
            top: 50%;
            transform: translateY(-50%);
            g {
                opacity: 1;
            }
        }
        input {
            width: 100%;
            height: 100%;
            background: transparent;
            border-radius: 26px;
            border: none;
            outline: none;
            padding: 12px 12px 12px 50px;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            &::placeholder {
                color: #C4C4C4;
            }
        }
    }
    .select-all-btn {
        background: transparent;
        border: none;
        font-weight: 600;
        font-size: 16px;
        line-height: 158.02%;
        color: #435BF4;
        margin: 0 24px 16px 24px;
        transition: .3s ease-in-out;
        &:hover {
            opacity: .7;
        }
    }
}

@media (max-width: 1500px) {
    .sa-customer-selection {
        &__all, &__selected {
            width: 50%;
        }
        .sa-all-customers-item .content-counts-user {
            padding: 2px 4px;
            font-size: 14px;
            font-weight: 500;
        }
        .sa-all-customers-item__bottom {
            margin-left: -44px;
            gap: 4px;
        }
        .sa-selected-customers-item__bottom {
            gap: 4px;
        }
        .sa-selected-customers-item__footer {
            position: relative;
            .sa-selected-customers-item__users {
                position: absolute;
                top: -38px;
                right: 0;
                .content-counts-user {
                    padding: 2px 4px;
                    font-size: 14px;
                    font-weight: 500;
                }
            }
        }
    }
}

@media (max-width: 1300px) {
    .sa-customer-selection {
        flex-direction: column;
        gap: 40px;
        > div {
            width: 100%;
            padding: 0;
        }
    }
}

@media (max-width: 600px) {
    .sa-customer-selection__all--card {
        box-shadow: none;
        padding: 0;
    }
    .sa-customer-selection__search {
        margin-left: 0;
    }
    .sa-customer-selection .select-all-btn {
        margin-left: 0;
    }
    .sa-all-customers-item__bottom .content--counts {
        border-width: 1px;
        padding: 0 2px;
        height: 30px;
    }
    .sa-selected-customers-item__bottom .content--counts {
        border-width: 1px;
        padding: 0 2px;
        height: 30px;
    }
    .sa-customer-selection__scrollbar {
        overflow-x: hidden;
        border: 1px solid rgba(0, 0, 0, 0.1019607843);
        padding: 5px 0;
        border-radius: 10px;
    }
    .sa-customer-selection__header {
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        height: auto;
        .sa-customer-selection__header--right {
            width: 100%;
            justify-content: space-between;
        }
    }
}
</style>
