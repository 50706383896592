import router from "@/router";
import store from "@/store";
import moment from "moment";
import {
    SACCBusinessModel,
    SuperAdminSelectCustomersContainer
} from "@/store/models/bank/feedback/SuperAdminSelectCustomersContainer";
import { SuperAdminSelectBanksContainer } from "@/store/models/bank/feedback/SuperAdminSelectBanksContainer";
import { BankModelMin } from "@/store/models/bank/BankModelMin";
import { SurveyScheduleStatusEnum } from "@/store/enums/SurveyScheduleStatusEnum";
import { SurveyQuestionsLengthEnum } from "@/store/enums/SurveyQuestionsLengthEnum";
import { getDateDiff, getEndOfDay } from "@/buying-teams/helpers/DateHelper";
import { CustomQuestionsContainer } from "@/store/models/shared/CustomQuestionsContainer";

type feedbackSessionPayloadType = {
    surveyId: number,
    selectedBanks: BankModelMin[],
    selectedCustomers: SACCBusinessModel[],
    selectedCustomQuestions: any,
    scheduleStatus: SurveyScheduleStatusEnum,
    title: string,
    description: string,
    length: SurveyQuestionsLengthEnum,
    phases: string[],
    productAreas: string[],
    sessionStart: Date,
    sessionEnd: Date,
}

export enum SAFeedbackStepsEnum {
    SELECT_BANKS = 1,
    SET_BASIC_INFO = 2,
    SESSION_DESCRIPTION = 3,
    SET_QUESTIONNAIRE = 4,
    SELECT_CUSTOMER = 5,
    SESSION_SUMMARY = 6,
}

const STEPS_COUNT = 6;

export class SuperAdminFeedbackCreationContainer {
    allProductAreas: string[] = [];
    stepsCount: number = STEPS_COUNT;
    currentStep: SAFeedbackStepsEnum = SAFeedbackStepsEnum.SELECT_BANKS;
    customersData: SuperAdminSelectCustomersContainer | null = null;
    banksData: SuperAdminSelectBanksContainer | null = null;
    customQuestionsData: CustomQuestionsContainer | null = null;
    questionsDataList: any[] = [];
    isCustomQuestionsOpen: boolean = false;
    isSelectDeadlineTouched: boolean = false;

    feedbackSessionPayload: feedbackSessionPayloadType = {
        surveyId: 0,
        selectedBanks: [],
        selectedCustomers: [],
        selectedCustomQuestions: [],
        scheduleStatus: SurveyScheduleStatusEnum.NOW,
        title: "",
        description: "",
        length: SurveyQuestionsLengthEnum.STANDARD,
        phases: [...store.getters.phases],
        productAreas: [],
        sessionStart: new Date(),
        sessionEnd: new Date(),
    }

    constructor(productAreas: string[], banks: any[], countries: string[]) {
        this.allProductAreas = productAreas;
        this.feedbackSessionPayload.productAreas = this.allProductAreas;
        this.banksData = new SuperAdminSelectBanksContainer(banks, countries);
    }

    get stepsPercentage() {
        return this.currentStep <= (this.stepsCount - 1) ? this.currentStep * (100 / this.stepsCount) : 100
    }

    get questionsLength() {
        return this.feedbackSessionPayload.length === SurveyQuestionsLengthEnum.QUICK ? 3 : this.feedbackSessionPayload.length === SurveyQuestionsLengthEnum.STANDARD ? 7 : 14;
    }

    get differenceDay() {
        return getDateDiff(this.feedbackSessionPayload.sessionEnd, this.feedbackSessionPayload.sessionStart);
    }

    get selectedBanksCountries() {
        const countries: Set<string> = new Set<string>();
        this.feedbackSessionPayload.selectedBanks.forEach((b) => {
            b.countries?.forEach((c) => {
                countries.add(c);
            })
        })
        return countries ? Array.from(countries.values()) : [];
    }

    get feedbackSessionDraftPayload() {
        return {
            title: this.feedbackSessionPayload.title,
            description: this.feedbackSessionPayload.description,
            phases: this.feedbackSessionPayload.phases,
            product_areas: this.feedbackSessionPayload.productAreas,
            length: this.feedbackSessionPayload.length,
            session_start: this.feedbackSessionPayload.sessionStart,
            session_end: getEndOfDay(this.feedbackSessionPayload.sessionEnd),
            countries: this.selectedBanksCountries,
            repeat_session: ""
        }
    }

    get feedbackSessionLaunchPayload() {
        return {
            survey_id: this.feedbackSessionPayload.surveyId,
            title: this.feedbackSessionPayload.title,
            description: this.feedbackSessionPayload.description,
            banks: this.feedbackSessionPayload.selectedBanks.map((b) => {
                return {
                    countries: b.countries,
                    id: b.id
                }
            })
        }
    }

    setCustomersData(response: any) {
        this.customersData = new SuperAdminSelectCustomersContainer(response, this.feedbackSessionPayload.selectedBanks);
    }

    setBanksData(banks: any[], countries: string[]) {
        this.banksData = new SuperAdminSelectBanksContainer(banks, countries);
    }

    setCustomQuestionsData(questions: any[]) {
        if (this.customQuestionsData) {
            this.customQuestionsData.setSavedQuestionsData(questions);
        } else {
            this.customQuestionsData = new CustomQuestionsContainer(questions, this.feedbackSessionPayload.productAreas);
        }
        this.isCustomQuestionsOpen = true;
    }

    checkResetCustomQuestionsContainer() {
        if (this.customQuestionsData && (JSON.stringify(this.customQuestionsData?.surveyProductAreas) !== JSON.stringify(this.feedbackSessionPayload.productAreas))) {
            this.customQuestionsData.resetQuestionContainer(this.feedbackSessionPayload.productAreas);
            this.feedbackSessionPayload.selectedCustomQuestions = [];
        }
    }

    setProductAreas(productAreas: string[]) {
        this.allProductAreas = productAreas;
    }

    setDefaultEndDate() {
        let date = new Date(moment(this.feedbackSessionPayload.sessionStart).format());
        let endDate: any = moment(date.setDate(date.getDate() + this.questionsLength));

        if (!this.isSelectDeadlineTouched || (this.isSelectDeadlineTouched && this.differenceDay <= 2)) {
            this.feedbackSessionPayload.sessionEnd = new Date(endDate);
            this.isSelectDeadlineTouched = false;
        }

        this.feedbackSessionPayload.sessionStart = new Date(moment(this.feedbackSessionPayload.sessionStart).format());
    }

    setQuestionsData(surveyId: number, questions: any[]) {
        this.feedbackSessionPayload.surveyId = surveyId;

        let productAreas: Set<string> = new Set<string>();
        let phases: Set<string> = new Set<string>();

        questions.forEach((item: any) => {
            productAreas.add(item.product_area);
            phases.add(item.phase);
        });

        this.questionsDataList = Array.from(productAreas.values()).map(area => {
            return {
                title: area,
                phases: Array.from(phases.values()).map(phase => {
                    return {
                        title: phase,
                        questionList: questions.filter(item => item.phase === phase && item.product_area === area).map(item => {
                            return {
                                question: item.question,
                                is_enabled: true,
                                id: item.id,
                            };
                        })
                    };
                })
            };
        });
    }

    activeQuestionsCount(value = []) {
        let count = 0
        value.forEach((item: any) => {
            count += item.questionList.filter(val => val.is_enabled).length
        })
        return count
    }

    nextStep() {
        this.currentStep ++;

        switch (this.currentStep) {
            case SAFeedbackStepsEnum.SESSION_DESCRIPTION: {
                if (!this.feedbackSessionPayload.title) {
                    this.feedbackSessionPayload.title = this.feedbackSessionPayload.phases.join(', ')
                }
                if (!this.feedbackSessionPayload.description) {
                    this.feedbackSessionPayload.description = this.feedbackSessionPayload.productAreas.join(', ')
                }
                this.setDefaultEndDate();
                return;
            }
        }
    }

    prevStep() {
        if (this.currentStep !== SAFeedbackStepsEnum.SELECT_BANKS) {
            this.currentStep --;
        } else {
            router.go(-1);
        }
    }

    handleSelectBanks() {
        this.feedbackSessionPayload.selectedBanks = this.banksData?.getSelectedBanks
            ? this.banksData?.getSelectedBanks.map((b) => new BankModelMin({...b, countries: b.selectedCountries}))
            : [];
        this.nextStep();
    }

    handleSelectCustomers() {
        this.feedbackSessionPayload.selectedCustomers = this.customersData?.getFinalData ? this.customersData?.getFinalData : [];
    }

    setSelectedCustomQuestions() {
        this.feedbackSessionPayload.selectedCustomQuestions = this.customQuestionsData?.newQuestions
            .filter((q) => q.isValid())
            .map((q) => q.payloadData);
    }
}
