<template>
    <div class="sa-new-feedback__card" id="sa-new-feedback-s2">
        <el-form
            :model="creationContainer.feedbackSessionPayload"
            ref="createFeedbackFormRef"
            :rules="createFeedbackFormRules">
            <div class="row">
                <div class="col-md-5">
                    <div class="sa-step2__left">
                        <div class="form-item mb-10">
                            <div class="sa-step2__label">{{ $t('Schedule for') }}</div>
                            <el-radio-group v-model="creationContainer.feedbackSessionPayload.scheduleStatus" @change="changeScheduleStatus" class="schedule-for-radio-btn">
                                <el-radio-button :label="SurveyScheduleStatusEnum.NOW">{{ $t('Now') }}</el-radio-button>
                                <el-radio-button :label="SurveyScheduleStatusEnum.LATER">{{ $t('Later') }}</el-radio-button>
                            </el-radio-group>
                        </div>

                        <div class="form-item mb-10">
                            <div class="sa-step2__label" :class="{'is-disabled': creationContainer.feedbackSessionPayload.scheduleStatus === SurveyScheduleStatusEnum.NOW}">
                                {{ $t('Select Date & Time') }}
                            </div>
                            <div class="select-date-time">
                                <el-date-picker
                                    :disabled="creationContainer.feedbackSessionPayload.scheduleStatus === SurveyScheduleStatusEnum.NOW"
                                    v-model="start_date"
                                    :clearable="false"
                                    @change="selectDate($event)"
                                    :disabled-date="disabledDate"
                                    type="date"
                                    placeholder="Pick a day" />

                                <el-time-picker
                                    :disabled="creationContainer.feedbackSessionPayload.scheduleStatus === SurveyScheduleStatusEnum.NOW"
                                    v-model="start_time"
                                    :clearable="false"
                                    @change="selectTime($event)"
                                    placeholder="Arbitrary time"
                                    format="HH:mm"
                                />
                            </div>
                        </div>

                        <div class="form-item">
                            <div class="sa-step2__label">{{ $t('Select a Phase(s):') }}</div>
                            <el-form-item prop="phases">
                                <el-checkbox-group v-model="creationContainer.feedbackSessionPayload.phases" class="select-phase">
                                    <el-checkbox-button v-for="phase in phasesOptions" :label="phase" name="priority"></el-checkbox-button>
                                </el-checkbox-group>
                            </el-form-item>
                        </div>

                    </div>
                </div>
                <div class="col-md-7">
                    <div class="sa-step2__right">
                        <div class="form-item mb-10">
                            <div class="sa-step2__label">{{ $t('How many predefined questions per Product Area?') }}</div>
                            <el-form-item prop="length">
                                <el-radio-group v-model="creationContainer.feedbackSessionPayload.length" class="questions-length">
                                    <el-radio-button :label="SurveyQuestionsLengthEnum.QUICK" name="priority">
                                        {{ $t("Quick 2-3 Mins") }}
                                    </el-radio-button>
                                    <el-radio-button :label="SurveyQuestionsLengthEnum.STANDARD" name="priority">
                                        {{ $t("Normal 3-5 Mins") }}
                                    </el-radio-button>
                                    <el-radio-button :label="SurveyQuestionsLengthEnum.DETAILED" name="priority">
                                        {{ $t("Detailed 5-7 Mins") }}
                                    </el-radio-button>
                                </el-radio-group>
                            </el-form-item>
                        </div>
                        <div class="form-item">
                            <div class="sa-step2__label opacity-75">{{ $t('Product Areas') }}</div>
                            <el-form-item prop="productAreas">
                                <SelectBoxWithModal
                                    v-model="creationContainer.feedbackSessionPayload.productAreas"
                                    self-value
                                    multiple
                                    :all-selected-text="$t('All Selected')"
                                    :custom-selection-text="$t('Multiple Selected')"
                                    :options="allProductAreas"
                                />
                            </el-form-item>
                        </div>
                    </div>
                </div>
            </div>
        </el-form>

        <div class="w-100 d-flex justify-content-end mt-10">
            <button
                class="main-btn d-block btn continue-button"
                :disabled="false"
                @click="handleNextStep"
            >
                {{ $t('Continue') }}
            </button>
        </div>
    </div>
</template>

<script>
import { SurveyScheduleStatusEnum } from "@/store/enums/SurveyScheduleStatusEnum";
import store from "@/store";
import SelectBoxWithModal from "@/buying-teams/shared-components/inputs/SelectBoxWithModal";
import { SuperAdminFeedbackCreationContainer } from "@/store/models/super-admin/SuperAdminFeedbackCreationContainer";
import moment from "moment";
import { SurveyQuestionsLengthEnum } from "@/store/enums/SurveyQuestionsLengthEnum";

export default {
    name: "SAStepTwoBasicInfo",
    components: {
        SelectBoxWithModal
    },
    props: {
        creationContainer: [Object, SuperAdminFeedbackCreationContainer]
    },
    data() {
        return {
            start_date: new Date(),
            start_time: new Date(),
            SurveyScheduleStatusEnum,
            SurveyQuestionsLengthEnum
        }
    },
    computed: {
        phasesOptions() {
            return store.getters.phases;
        },
        allProductAreas() {
            return this.creationContainer.allProductAreas;
        },
        createFeedbackFormRules() {
            return {
                phases: [
                    { required: true, message: this.$t("Please select Phases"), trigger: "change" }
                ],
                length: [
                    { required: true, message: this.$t("Please select Predefined Questions"), trigger: "change" }
                ],
                productAreas: [
                    { required: true, message: this.$t("Please input Product Area"), trigger: "change" }
                ]
            }
        }
    },
    methods: {
        disabledDate(time) {
            return time.getTime() < Date.now();
        },
        selectDate() {
            this.creationContainer.feedbackSessionPayload.sessionStart = this.start_date
            this.selectTime();
        },
        selectTime() {
            let date = moment(this.creationContainer.feedbackSessionPayload.sessionStart).format("YYYY/MM/DD") + " " + moment(this.start_time).format("HH:mm") + ':00';
            this.creationContainer.feedbackSessionPayload.sessionStart = new Date(date);
        },
        changeScheduleStatus() {
            if (this.creationContainer.feedbackSessionPayload.scheduleStatus === SurveyScheduleStatusEnum.NOW) {
                this.start_date = new Date();
                this.start_time = new Date();
                this.creationContainer.feedbackSessionPayload.sessionStart = new Date();
            }
        },
        handleNextStep() {
            this.$refs.createFeedbackFormRef.validate((valid) => {
                if (valid) {
                    this.creationContainer.nextStep();
                }
            })
        },
    },
    created() {
        this.changeScheduleStatus();
    }
}
</script>

<style lang="scss">
#sa-new-feedback-s2 {
    margin-top: 41px;
    .sa-step2 {
        &__label {
            font-weight: 500;
            font-size: 17px;
            line-height: 21px;
            color: #4F4F4F;
            margin-bottom: 18px;
            &.is-disabled {
                color: #4F4F4F;
                opacity: 0.2;
            }
        }
        &__left {
            padding-right: 49px;
            border-right: 1px solid rgba(0, 0, 0, 0.05);
            .schedule-for-radio-btn {
                width: 100%;
                max-width: 350px;
                display: flex;
                background: #F2F2F2;
                border-radius: 7px;
                padding: 4px;
                .el-radio-button {
                    width: 100%;
                    .el-radio-button__inner {
                        background: #F2F2F2;
                        border: none;
                        font-size: 17px;
                        line-height: 21px;
                        color: #838383;
                        border-radius: 5px;
                    }
                    &.is-active {
                        .el-radio-button__inner {
                            background: #fff;
                            color: #000000;
                        }
                    }
                }
                .el-radio-button__inner {
                    width: 100%;
                    box-shadow: none;
                }
            }
            .select-date-time {
                max-width: 350px;
                display: flex;
                gap: 5px;
                .el-date-editor {
                    height: 47px;
                }
                .el-input__inner {
                    height: 47px;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 19px;
                    color: #838383;
                    background: rgba(242, 242, 242, 0.298);
                    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 4px inset;
                    border-radius: 7px;
                    border: none;
                }
                .el-icon svg path {
                    fill: #838383;
                }
                .el-input.is-disabled {
                    opacity: 0.3;
                    .el-input__inner {
                        box-shadow: none;
                    }
                }
            }
            .select-phase {
                display: flex;
                gap: 12px;
                .el-checkbox-button__inner {
                    background: #F8F8F8;
                    border: 2px solid #435BF4;
                    border-radius: 8px;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                    color: #435BF4;
                    box-shadow: none;
                    padding: 13px;
                }
                .el-checkbox-button.is-checked {
                    .el-checkbox-button__inner {
                        background: #435BF4;
                        color: #fff;
                    }
                }
            }
        }
        &__right {
            padding-left: 20px;
            .questions-length {
                display: flex;
                gap: 14px;
                .el-radio-button {
                    .el-radio-button__inner {
                        background: rgba(67, 91, 244, 0.05);
                        border-radius: 32px;
                        border: none;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 19px;
                        color: #435BF4;
                        padding: 18px 24px;
                        box-shadow: none;
                    }

                    &.is-active {
                        .el-radio-button__inner {
                            background: #435BF4;
                            color: #fff;
                        }
                    }
                }
            }
            .select-box-with-badge {
                .el-input__inner {
                    background: #F8F8F8;
                    box-shadow: inset 0 1.12463px 4.4985px rgba(0, 0, 0, 0.05);
                }
            }
        }
    }

    @media (max-width: 1400px) {
        .sa-step2 {
            &__right .questions-length {
                flex-wrap: wrap;
                .el-radio-button .el-radio-button__inner {
                    padding: 12px 18px;
                    font-size: 14px;
                }
            }
            &__left {
                .schedule-for-radio-btn {
                    max-width: 220px;

                    .el-radio-button .el-radio-button__inner {
                        padding: 8px 14px;
                        font-size: 14px;
                    }
                }
                .select-phase {
                    flex-wrap: wrap;
                }
            }
        }
    }

    @media (max-width: 768px) {
        .sa-step2 {
            &__right {
                padding-left: 0;
            }
            &__left {
                padding-right: 0;
                border-right: none;
                padding-bottom: 20px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.05);
                margin-bottom: 20px;
            }
        }
    }
}
</style>
