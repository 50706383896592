import { BankModelMin } from "@/store/models/bank/BankModelMin";

export class SuperAdminSelectBanksContainer {
    allBanks: BankModelMin[];
    selectedBanks: Map<Number, SASBCBankModel>;

    constructor(banks: any[], countries: string[]) {
        this.allBanks = banks.map(b => new BankModelMin({...b, countries}));
        this.selectedBanks = new Map<number, SASBCBankModel>();
    }

    get getSelectedBanks(): SASBCBankModel[] {
        return Array.from(this.selectedBanks.values());
    }

    isBankSelected(bank: BankModelMin) {
        return Array.from(this.selectedBanks.values()).some(e => e.id === bank.id);
    }

    selectBank(bank: BankModelMin, selectedCountries: string[]) {
        if (!selectedCountries.length) {
            this.selectedBanks.delete(bank.id);
            return;
        }
        this.selectedBanks.set(bank.id, new SASBCBankModel(
            bank,
            selectedCountries
        ))
    }

    deleteBank(bank: BankModelMin) {
        this.selectedBanks.delete(bank.id);
    }

    deleteAllSelectedBanks() {
        this.selectedBanks = new Map<number, SASBCBankModel>();
    }

    getFilteredData(search: string): BankModelMin[] {
        let data: BankModelMin[] = this.allBanks;
        return search ? data.filter((item: BankModelMin) => {
            return item.name.toLowerCase().includes(search.toLowerCase())
        }) : data;
    }
}

export class SASBCBankModel extends BankModelMin {
    selectedCountries: string[] = [];

    constructor(data: any, selectedCountries) {
        super(data);
        this.selectedCountries = selectedCountries;
    }
}
